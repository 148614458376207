import React from 'react';
import { Controller } from 'react-hook-form';
import SwitchWithIcon from "components/common/SwitchWithIcon";
import { URL_ROSTERING_GET_RECURRING_SHIFT_ON_EDIT_MESSAGE } from 'store/services/URL';
import { apiGetRequest } from 'helpers/Requests';

const RecurringShiftSwitch = ({
  control,
  watchFormData,
  selectedShift ,
  setValue,
  setRecurringEffectedShiftCount=null,
  setRunAgain=null,
  setRecurringType=null,
  setRecurrInterval=null,
  setIsRecurringShift=null,
  setLoading

}) => {
  return (
    <div className='flex gap-3'>
      <label className='text-gray-700 text-sm font-medium mb-1'>
        Do you want to apply changings on all the other recurring shifts?
      </label>
      <div>
        <Controller
          name={`recurring_impact`}
          control={control}
          defaultValue={watchFormData?.recurring_impact || false}
          render={({ field }) => (
            <SwitchWithIcon
              onChange={(value) => {
                field.onChange(value);
                setValue(`recurring_impact`, value);
                if(value){
                    setLoading(true)
                    if(setIsRecurringShift){
                        setIsRecurringShift(true)
                    }
                
                apiGetRequest(
                  `${URL_ROSTERING_GET_RECURRING_SHIFT_ON_EDIT_MESSAGE}/${selectedShift?.uuid}`
                )
                  .then((res) =>
                    setRecurringEffectedShiftCount(res?.data?.data)
                  )
                  .catch((error) => console.log({ error })).finally(()=>setLoading(false))
                  }else{
                    setRecurringEffectedShiftCount(null)
                  }
                  if(setRunAgain && setRecurringType && setRecurrInterval){
                    setRunAgain((prev) => !prev);
                    setRecurringType(null);
                    setRecurrInterval(null);
                  }
              
              }}
              checked={field.value}
              // disabled={checkRunningShiftStatus(selectedShift?.status)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default RecurringShiftSwitch;
