import { Navigate } from "react-router";
import Dashboard from "../pages/Dashboard/DashboardNew";
import HRM from "../pages/HRM/Pages/Dashboard/Dashboard";
import ServiceProviderSetup from "../pages/ServiceProvider/ServiceProviderSetup/ServiceProviderSetup";
import Emailcode from "../pages/Authentication/EmailCode/Emailcode";
import TeamMemberSetup from "../pages/TeamMemberSetup/TeamMemberSetup";
import COSParticipantProfile from "../pages/SupportCoordination/Participant/ParticipantProfile/index";
import ParticipantList from "../pages/ServiceProvider/ParticipantsList/index";
import ParticipantProfile from "../pages/ServiceProvider/ParticipantProfile/index";
import AllForms from "../pages/ServiceProvider/AllForms/index";
import EditFormTemplate from "../components/DynamicForms/EditFormTemplate/EditFormTemplate";
import ViewFormTemplate from "../components/DynamicForms/ViewFormTemplate/index";
import { ReportForm } from "../pages/ReportForm/ReportForm";
import InviteTeamMember from "../components/Pages/InviteTeamMember/InviteTeamMember";
import ServiceProviders from "../pages/SuperAdmin/ServiceProviders/index";
import Complaints from "../pages/ServiceProvider/Complaint/index";
import Feedback from "../pages/ServiceProvider/Feedback/index";
import AskQuestion from "pages/ServiceProvider/AskQuestion/index";
import SubmittedSingleFormView from "../components/DynamicForms/SubmittedSingleFormView";
import SubmittedSingleFormViewHRM from "../components/DynamicForms/SubmittedSingleFormViewHRM";
import AllApplicants from "../pages/HRM/Pages/Applicant/AllApplicants/index";
import ApplicantProfile from "../pages/HRM/Pages/Applicant/Profile";
import ApplicantAssessment from "../pages/HRM/Pages/Applicant/Assessment";
import EmployeeDashboard from "../pages/EmployeePortal/Pages/Dashboard/Dashboard";
import HumanBody from "../components/common/HumanBody/HumanBody";
import Documents from "../pages/EmployeePortal/Pages/Documents/index";
import Participants from "../pages/EmployeePortal/Pages/Participants/Participants";
import EmployeeSettings from "../pages/EmployeePortal/Pages/Settings/Settings";
import EmployeeComplaints from "../pages/EmployeePortal/Pages/Complaints/Complaints";
import SubmittedFormUpdate from "../components/DynamicForms/SubmittedFormUpdate";
import SubmittedFormUpdateHRM from "../components/DynamicForms/SubmittedFormUpdateHRM";
import WorkerProfile from "../pages/HRM/Pages/Worker";
import TeamManagment from "../pages/HRM/Pages/TeamManagment/index";
import EmployeeFeedback from "../pages/EmployeePortal/Pages/Feedback/EmployeeFeedback";
import SpPolicies from "../pages/ServiceProvider/SpPolicies/SpPolicies";
import EmployeePolicies from "../pages/EmployeePortal/Pages/Policies/Policies";
import ManagerList from "../pages/ServiceProvider/Manager/index";
import CatalogServices from "../pages/SupportCoordination/CatalogServices/index";
import ExternalParticipantProfile from "../pages/SupportCoordination/ExternalParticipantProfile/ExternalParticipantProfile";
import ExternalServiceProvider from "../pages/SupportCoordination/ExternalServiceProvider/index";
import FundingBudget from "../pages/SupportCoordination/Funding/FundingBudget/index";
import CosParticipant from "../pages/SupportCoordination/Participant/ParticipantList/index";
import CaseNotesHistory from "../pages/SupportCoordination/Participant/CaseNotesHistory/index";
import Reporting from "../pages/SupportCoordination/Participant/Reporting/index";
import NdisClaim from "../pages/SupportCoordination/NDISClaim/index";
import NdisClaimNew from "../pages/SupportCoordination/NDISClaim/NDISClaimNew/index";
import NDISClaimReview from "../pages/SupportCoordination/NDISClaim/NDISClaimReview/index";
import CreateNewInvoices from "../pages/SupportCoordination/Participant/COSInvoices/createNewInvoices/index";
import CosInvoices from "../pages/SupportCoordination/Participant/COSInvoices/index";
import CosInvoicesPreview from "../pages/SupportCoordination/Participant/COSInvoices/InvoicesPreview/index";
import CosDashboard from "../pages/SupportCoordination/Participant/Dashboard/index";
import COSSettings from "../pages/SupportCoordination/Participant/settings/index";
import CategorySettingPage from "../pages/SupportCoordination/Participant/settings/CategoryPage/index";
import ContactSettingPage from "../pages/SupportCoordination/Participant/settings/ContactPage/index";
import PrioritySettingPage from "../pages/SupportCoordination/Participant/settings/PriorityPage/index";
import OnBoardingForm from "../pages/EmployeePortal/Pages/OnBoardingForm/OnBoardingFrom";
import ConfirmedPassword from "pages/Authentication/ForgetPassword/ConfirmedPasswoerd/ConfirmedPasswoerd";
import ForgetPassword from "pages/Authentication/ForgetPassword/ForgetPassword";
import SetPassword from "pages/Authentication/ForgetPassword/SetPassword/SetPassword";
import SignUp from "pages/Authentication/SignUp/SignUp";
import SignIn from "pages/Authentication/SignIn/SignIn";
import ComingSoonPage from "components/common/ComingSoonPage/ComingSoonPage";
import CreateFormTemplate from "components/DynamicForms/CreateFormTemplate/CreateFormTemplate";
import SubmittedForms from "pages/EmployeePortal/Pages/SubmittedForms/index";
import ExportTimeSheet from "pages/Rostering/ExportTimeSheet/index";
import RosteringDashboard from "pages/Rostering/Dashboard/Dashboard";
import Schedule from "pages/Rostering/Schedule/index";
import TimeSheet from "pages/Rostering/TimeSheet/index";
import NotificationSettings from "pages/ServiceProvider/NotificationSettings";
import RolesAndPermissions from "pages/ServiceProvider/RolesPermissionsSettings/index";
import COSReportsContainer from "pages/Reporting/COSReports/COSReports.container";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PAYROLL_PLATFORMS,
  PERMISSION_KEYS,
  PERMISSION_KEYS_CHILDREN,
} from "store/constants";
import ReleaseNotes from "pages/ReleaseNotes";
import PayrollAuthCallback from "pages/Authentication/payroll/PayrollAuthCallback";
import PayrollSetting from "pages/ServiceProvider/PayrollSetting";
import PricingTable from "pages/Stripe/Pricing/PricingTable";
import SubscriptionDetailsDashboard from "pages/Stripe/Subscription/Dashboard";
import { USER_ROLE } from "Constants/constant";
import SpSetting from "../pages/ServiceProvider/SpSetting/SpSetting/index";
import StaffCompliance from "pages/ServiceProvider/SpSetting/StaffCompliance/index";
import PersonalSetting from "../pages/ServiceProvider/SpSetting/index";
import RosterInvoicing from "pages/Rostering/Invoicing/RosterInvoices";
import CreateRosterInvoicing from "pages/Rostering/Invoicing/CreateRosterInvoicing/CreateRosterInvoicing";
import RosterInvoicePreview from "pages/Rostering/Invoicing/InvoicePreview";
import ReportingModule from "pages/Reporting";
import ParticipantExpiringDocReportsContainer from "pages/Reporting/ParticipantExpiringDocReports/ParticipantExpiringDocReports.container";
import ComplaintsReports from "pages/Reporting/ComplaintsReports/ComplaintsReports";
import FeedbackReports from "pages/Reporting/FeedbackReports/FeedbackReports";
import IncidentReports from "pages/Reporting/IncidentReports/IncidentReports.container";
import InvoicingReports from "pages/Reporting/InvoicingReports";
import ParticipantListReports from "pages/Reporting/ParticipantListReports/index";
import RocReports from "pages/Reporting/RocReports/index";
import FundingReportsContainer from "pages/Reporting/FundingReports/FundingReports.container";
import KYPReportsContainer from "pages/Reporting/KYPReports/KYPReports.container";
import WorkerListReports from "pages/Reporting/WorkerListReports/index";
import ROCWeeklyReport from "pages/Reporting/ROCWeeklyReport/ROCWeeklyReport.container";
import BSPReports from "pages/Reporting/BSPReports/BSPReports";
import SummaryInvoicingReport from "pages/Reporting/SummaryInvoicingReport/SummaryInvoiceReports.container";
import DetailedInvoicingReport from "pages/Reporting/DetailedInvoicingReports/DetailedInvoicingReports.container";
import CaseNoteReportsContainer from "pages/Reporting/CasenoteReports";
import RiskAssessmentReports from "pages/Reporting/RiskAssessmentReports/RiskAssessmentReports.container";

import ProgressReports from "pages/Reporting/ProgressReports/ProgressReports";
import COSReports from "pages/Reporting/COSReports/COSReports";
import TimesheetReportsContainer from "pages/Reporting/TimesheetReports/TimesheetReports.container";
import WorkerExpiringDocReportsContainer from "pages/Reporting/WorkerExpiringDocumentsRedports/WorkerExpiringDocReports.container";

const cosRoutes = [
  //support coordination
  {
    path: "/cos/participant-funding/:uuid/:ptUUID",
    element: <FundingBudget />,
    permissionName: PERMISSION_KEYS.PARTICIPANT,
    permissionChild:
      BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.FUNDING.SHOW,
  },
  {
    path: "/cos/dashboard",
    element: <CosDashboard />,
    permissionName: PERMISSION_KEYS.COS,
  },
  {
    path: "/cos/catalog-services",
    element: <CatalogServices />,
    permissionName: PERMISSION_KEYS.COS,
  },
  {
    path: "/cos/external-service-provider",
    element: <ExternalServiceProvider />,
    permissionName: PERMISSION_KEYS.COS,
  },
  {
    path: "/cos/external-participant-profile",
    element: <ExternalParticipantProfile />,
    permissionName: PERMISSION_KEYS.COS,
  },
  {
    path: "/cos/participant-list",
    element: <CosParticipant />,
    permissionName: PERMISSION_KEYS.COS,
  },
  {
    path: "/cos/participant-profile/:uuid",
    element: <COSParticipantProfile />,
    permissionName: PERMISSION_KEYS.COS,
  },
  {
    path: "/cos/casenotes-history",
    element: <CaseNotesHistory />,
    permissionName: PERMISSION_KEYS.COS,
  },
  //COS Reporting
  {
    path: "/cos/reporting",
    element: <Reporting />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.REPORTING.SHOW,
  },

  //COS NDIS Claim
  {
    path: "/cos/ndis-claims",
    element: <NdisClaim />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.NDIS_CLAIM.SHOW,
  },
  {
    path: "/cos/ndis-claims-new",
    element: <NdisClaimNew />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.NDIS_CLAIM.SHOW,
  },
  {
    path: "/cos/ndis-claims-review/:uuid",
    element: <NDISClaimReview />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.NDIS_CLAIM.SHOW,
  },
  // COS Invoices
  {
    path: "/cos/invoice",
    element: <CosInvoices />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.INVOICES.SHOW,
  },
  {
    path: "/cos/invoice-new",
    element: <CreateNewInvoices />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.INVOICES.SHOW,
  },
  {
    path: "/cos/invoice-preview/:uuid",
    element: <CosInvoicesPreview />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.INVOICES.SHOW,
  },
  //COS Settings
  {
    path: "/cos/settings",
    element: <COSSettings />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.SETTING.SHOW,
  },
  {
    path: "/cos/settings/category-setting",
    element: <CategorySettingPage />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.SETTING.SHOW,
  },
  {
    path: "/cos/settings/priority-setting",
    element: <PrioritySettingPage />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.SETTING.SHOW,
  },
  {
    path: "/cos/settings/contact-setting",
    element: <ContactSettingPage />,
    permissionName: PERMISSION_KEYS.COS,
    permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.COS.SETTING.SHOW,
  },
];

const spAdminAndManagerRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/release-notes", element: <ReleaseNotes /> },

  //Stripe Payment
  {
    path: "/subscription",
    element: <SubscriptionDetailsDashboard />,
    role: [USER_ROLE.Sp_Admin],
    isWildRoute: true,
  },
  {
    path: "/subscription/plans",
    element: <PricingTable />,
    role: [USER_ROLE.Sp_Admin],
    isWildRoute: true,
  },
  // { path: "/subscription/billing-method", element: <PaymentMethod /> },
  // { path: "/invoices", element: <StripeInvoices /> },

  //Settings
  {
    path: "/settings",
    element: <PersonalSetting />,
    role: [USER_ROLE.Sp_Admin],
  },
  {
    path: "/settings/sp-setting",
    element: <SpSetting />,
    role: [USER_ROLE.Sp_Admin],
  },
  {
    path: "/settings/worker-compliance-setting",
    element: <StaffCompliance />,
    role: [USER_ROLE.Sp_Admin],
  },
  {
    path: "/notification-setting",
    element: <NotificationSettings />,
  },
  {
    path: "/roles-permissions-setting",
    element: <RolesAndPermissions />,
  },
  {
    path: "/payroll-setting/:platform/:status",
    element: <PayrollSetting />,
  },
  {
    path: "/payroll-setting",
    element: <PayrollSetting />,
    permissionName: PERMISSION_KEYS.ROSTERING,
    permissionChild: PERMISSION_KEYS_CHILDREN.ROSTERING.TIMESHEET_EXPORT.SHOW,
  },
  {
    path: "/xero-auth/:apiResponse",
    element: <PayrollAuthCallback type={PAYROLL_PLATFORMS.XERO} />,
  },
  {
    path: "/quick-books-auth/:apiResponse",
    element: <PayrollAuthCallback type={PAYROLL_PLATFORMS.QUICKBOOKS} />,
  },

  //HRM
  { path: "/hrm", element: <HRM />, permissionName: PERMISSION_KEYS.HRM },
  {
    path: "/hrm/all-applicants",
    element: <AllApplicants />,
    permissionName: PERMISSION_KEYS.HRM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM.APPLICANT.SHOW,
  },
  {
    path: "/hrm/applicant-profile",
    element: <ApplicantProfile />,
    permissionName: PERMISSION_KEYS.HRM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM.APPLICANT.SHOW,
  },
  {
    path: "/hrm/applicant-assessment",
    element: <ApplicantAssessment />,
    permissionName: PERMISSION_KEYS.HRM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM.APPLICANT.ASSESSMENT,
  },
  {
    path: "/hrm/team-management",
    element: <TeamManagment />,
    permissionName: PERMISSION_KEYS.HRM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW,
  },
  {
    path: "/hrm/all-managers",
    element: <ManagerList />,
    permissionName: PERMISSION_KEYS.HRM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM.MANAGER.SHOW,
  },

  //Worker Profile
  {
    path: "/worker-profile/:parentSlug/:slug/view",
    element: <SubmittedSingleFormViewHRM />,
    permissionName: PERMISSION_KEYS.HRM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW,
  },
  {
    path: "/worker-profile/:parentSlug/:formId/update",
    element: <SubmittedFormUpdateHRM />,
    permissionName: PERMISSION_KEYS.HRM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW,
  },
  {
    path: "/worker-profile/:workerId",
    element: <WorkerProfile />,
    permissionName: PERMISSION_KEYS.HRM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW,
  },

  //Form Template Listing (PUBLISH/DRAFT)
  {
    path: "/forms-list",
    element: <AllForms role='crm' />,
    permissionName: PERMISSION_KEYS.CRM_FORM,
    permissionChild: PERMISSION_KEYS_CHILDREN.CRM_FORM.SHOW,
  },
  {
    path: "/forms-list-hrm",
    element: <AllForms role='hrm' />,
    permissionName: PERMISSION_KEYS.HRM_FORM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM_FORM.SHOW,
  },

  //Create New Form Template CRM & HRM
  {
    path: "/crm/create-new-report",
    element: <CreateFormTemplate role='crm' title='Create New Form' />,
    permissionName: PERMISSION_KEYS.CRM_FORM,
    permissionChild: PERMISSION_KEYS_CHILDREN.CRM_FORM.CREATE,
  },
  {
    path: "/hrm/create-new-report",
    element: <CreateFormTemplate role='hrm' title='Create New Form' />,
    permissionName: PERMISSION_KEYS.HRM_FORM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM_FORM.CREATE,
  },

  //Edit Form Template CRM & HRM FOR DRAFT TEMPLATES UPDATE
  {
    path: "/crm/edit-template/:form_uuid/:form_status",
    element: <CreateFormTemplate role='crm' title='Update Form' />,
    permissionName: PERMISSION_KEYS.CRM_FORM,
    permissionChild: PERMISSION_KEYS_CHILDREN.CRM_FORM.UPDATE,
  },
  {
    path: "/hrm/edit-template/:form_uuid/:form_status",
    element: <CreateFormTemplate role='hrm' title='Update Form' />,
    permissionName: PERMISSION_KEYS.HRM_FORM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM_FORM.UPDATE,
  },

  //Edit Form Template CRM & HRM FOR PUBLISH FORM TEMPLATE
  {
    path: "/edit-template/:slug",
    element: <EditFormTemplate role='crm' />,
    permissionName: PERMISSION_KEYS.CRM_FORM,
    permissionChild: PERMISSION_KEYS_CHILDREN.CRM_FORM.UPDATE,
  },
  {
    path: "/edit-template/hrm/:slug",
    element: <EditFormTemplate role='hrm' />,
    permissionName: PERMISSION_KEYS.HRM_FORM,
    permissionChild: PERMISSION_KEYS_CHILDREN.HRM_FORM.UPDATE,
  },

  //Participants
  {
    path: "/participant-list",
    element: <ParticipantList />,
    permissionName: PERMISSION_KEYS.PARTICIPANT,
  },

  //Participant Profile
  {
    path: "/sp-profile/:uuid",
    element: <ParticipantProfile />,
    permissionName: PERMISSION_KEYS.PARTICIPANT,
    permissionChild: PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL,
  },
  {
    path: "/sp-profile/:parentSlug/:slug/view",
    element: <SubmittedSingleFormView />,
    permissionName: PERMISSION_KEYS.PARTICIPANT,
    permissionChild: PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL,
  },
  {
    path: "/sp-profile/:parentSlug/:formId/update",
    element: <SubmittedFormUpdate />,
    permissionName: PERMISSION_KEYS.PARTICIPANT,
    permissionChild: PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL,
  },

  //Human Body Modal
  { path: "/humanbody", element: <HumanBody /> },

  //Forms For Participants
  {
    path: "/view-template/:ptuuid/:parentSlug/:slug/:isComplete",
    element: <ViewFormTemplate role='crm' />,
  },
  {
    path: "/view-template/:ptuuid/:parentSlug/:slug",
    element: <ViewFormTemplate role='crm' />,
  },
  {
    path: "/view-template/hrm/:ptuuid/:parentSlug/:slug/:isComplete",
    element: <ViewFormTemplate role='hrm' />,
  },
  {
    path: "/view-template/hrm/:ptuuid/:parentSlug/:slug",
    element: <ViewFormTemplate role='hrm' />,
  },

  //Teams
  { path: "/invite-member", element: <InviteTeamMember /> },

  //View template Only in Read-only view for both crm & hrm
  {
    path: "/view-template/crm/:formTemplateSlug/:templateStatus/read-only",
    element: <ViewFormTemplate role='crm' />,
  },
  {
    path: "/view-template/hrm/:formTemplateSlug/:templateStatus/read-only",
    element: <ViewFormTemplate role='hrm' />,
  },

  //complaints, feedback & Policy
  {
    path: "/complaints",
    element: <Complaints />,
    permissionName: PERMISSION_KEYS.COMPLAINT,
  },
  {
    path: "/feedbacks",
    element: <Feedback />,
    permissionName: PERMISSION_KEYS.FEEDBACK,
  },
  {
    path: "/policies",
    element: <SpPolicies />,
    permissionName: PERMISSION_KEYS.POLICy,
  },
  {
    path: "/ask-a-question",
    element: <AskQuestion />,
    permissionName: PERMISSION_KEYS.ASK_QUESTION,
  },

  //Support Coordination
  ...cosRoutes,

  // Rostering Routes
  {
    path: "/rostering/dashboard",
    element: <RosteringDashboard />,
    permissionName: PERMISSION_KEYS.ROSTERING,
  },
  {
    path: "/rostering/schedule",
    element: <Schedule />,
    permissionName: PERMISSION_KEYS.ROSTERING,
    permissionChild: PERMISSION_KEYS_CHILDREN.ROSTERING.SCHEDULE.SHOW,
  },
  {
    path: "/rostering/timesheet",
    element: <TimeSheet />,
    permissionName: PERMISSION_KEYS.ROSTERING,
    permissionChild: PERMISSION_KEYS_CHILDREN.ROSTERING.TIMESHEET.SHOW,
  },
  {
    path: "/rostering/timesheetExport",
    element: <ExportTimeSheet />,
    permissionName: PERMISSION_KEYS.ROSTERING,
    permissionChild: PERMISSION_KEYS_CHILDREN.ROSTERING.TIMESHEET_EXPORT.SHOW,
  },
  {
    path: "/rostering/invoicing",
    element: <RosterInvoicing />,
    permissionName: PERMISSION_KEYS.ROSTER_INVOICE,
  },
  {
    path: "/rostering/create-roster-invoice",
    element: <CreateRosterInvoicing />,
  },
  {
    path: "/rostering/roster-invoice-preview/:uuid",
    element: <RosterInvoicePreview />,
  },
  //Reporting routes
  {
    path: "/reporting",
    element: <ReportingModule />,
    // permissionName: PERMISSION_KEYS.ROSTER_INVOICE,
  },
  {
    path: "/reporting/roc-reports",
    element: <RocReports />,
  },
  {
    path: "/reporting/timesheet-reports",
    element: <TimesheetReportsContainer />,
  },
  {
    path: "/reporting/cos-reports",
    element: <COSReportsContainer />,
  },
  {
    path: "/reporting/incident-reports",
    element: <IncidentReports />,
  },
  {
    path: "/reporting/feedback-reports",
    element: <FeedbackReports />,
  },
  {
    path: "/reporting/complaints-reports",
    element: <ComplaintsReports />,
  },
  {
    path: "/reporting/participant-listing-reports",
    element: <ParticipantListReports />,
  },
  {
    path: "/reporting/participant-expiring-documents-reports",
    element: <ParticipantExpiringDocReportsContainer />,
  },
  {
    path: "/reporting/worker-list-reports",
    element: <WorkerListReports />,
  },
  {
    path: "/reporting/worker-expiring-documents-reports",
    element: <WorkerExpiringDocReportsContainer />,
  },
  {
    path: "/reporting/kyp-reports",
    element: <KYPReportsContainer />,
  },
  {
    path: "/reporting/invoicing-reports",
    element: <InvoicingReports />,
  },
  {
    path: "/reporting/cos-reports",
    element: <COSReports />,
  },
  {
    path: "/reporting/case-notes-reports",
    element: <CaseNoteReportsContainer />,
  },
  {
    path: "/reporting/bsp-reports",
    element: <BSPReports />,
  },
  {
    path: "/reporting/roc-weekly-reports",
    element: <ROCWeeklyReport />,
  },
  {
    path: "/reporting/risk-assessment-reports",
    element: <RiskAssessmentReports />,
  },
  {
    path: "/reporting/progress-reports",
    element: <ProgressReports />,
  },
  {
    path: "/reporting/funding-reports",
    element: <FundingReportsContainer />,
  },
  {
    path: "/reporting/summary-invoicing-reports",
    element: <SummaryInvoicingReport />,
  },
  {
    path: "/reporting/detailed-invoicing-reports",
    element: <DetailedInvoicingReport />,
  },
];

export const DynamicRoutes = {
  public: [
    { path: "/", element: <SignIn /> },
    { path: "/signup", element: <SignUp /> },
    //Invite Service Provider
    { path: "/sp-invitation/:id", element: <ServiceProviderSetup /> },
    { path: "/member-invitation", element: <TeamMemberSetup /> },
    { path: "/forget-password", element: <ForgetPassword /> },
    // { path: "/emailcomfirm", element: <EmailConfirm /> },
    { path: "/reset-password", element: <SetPassword /> },
    { path: "/confirmpassword", element: <ConfirmedPassword /> },
    //Email confirmation Code
    { path: "/email-verification-code/:encodedEmail", element: <Emailcode /> },

    // { path: "/reports-form", element: <ReportForm /> },

    //Public Form Submission
    {
      path: "/view-template/:ptuuid/:parentSlug/:slug/:publicId",
      element: <ViewFormTemplate role='crm' />,
    },

    {
      path: "/view-template/hrm/:ptuuid/:parentSlug/:slug/:publicId",
      element: <ViewFormTemplate role='hrm' />,
    },

    { path: "*", element: <Navigate to='/' /> },
  ],

  super_admin: [
    { path: "/", element: <Dashboard /> },
    { path: "/dashboard", element: <Dashboard /> }, // Dashboard
    { path: "/release-notes", element: <ReleaseNotes /> },
    //Create New Form Template CRM & HRM
    {
      path: "/crm/create-new-report",
      element: <CreateFormTemplate role='crm' title='Create New Form' />,
    },
    {
      path: "/hrm/create-new-report",
      element: <CreateFormTemplate role='hrm' title='Create New Form' />,
    },

    //Edit Form Template CRM & HRM FOR DRAFT FORM TEMPLATE
    {
      path: "/crm/edit-template/:form_uuid/:form_status",
      element: <CreateFormTemplate role='crm' title='Update Form' />,
    },
    {
      path: "/hrm/edit-template/:form_uuid/:form_status",
      element: <CreateFormTemplate role='hrm' title='Update Form' />,
    },

    //CRM View Form Template
    {
      path: "/crm/view-template/:ptuuid/:parentSlug/:slug/:isComplete",
      element: <ViewFormTemplate role='crm' />,
    },
    {
      path: "/crm/view-template/:parentSlug/:slug",
      element: <ViewFormTemplate role='crm' />,
    },

    //HRM View Form Template
    {
      path: "/hrm/view-template/:ptuuid/:parentSlug/:slug/:isComplete",
      element: <ViewFormTemplate role='hrm' />,
    },
    {
      path: "/hrm/view-template/:parentSlug/:slug",
      element: <ViewFormTemplate role='hrm' />,
    },

    //CRM & HRM Form listing
    { path: "/forms-list", element: <AllForms role='crm' /> },
    { path: "/forms-list-hrm", element: <AllForms role='hrm' /> },

    // Submitted Form TEMPLATE Edit CRM & HRM (PUBLISH FORM TEMPLATE)
    { path: "/edit-template/:slug", element: <EditFormTemplate role='crm' /> },
    {
      path: "/edit-template/hrm/:slug",
      element: <EditFormTemplate role='hrm' />,
    },

    //Service Provider Listing
    { path: "/sp-list", element: <ServiceProviders /> },
  ],

  sp_admin: [...spAdminAndManagerRoutes],

  sp_manager: [...spAdminAndManagerRoutes],

  sp_standard_user: [
    //On Boarding Form
    { path: "/", element: <OnBoardingForm /> },
    { path: "/release-notes", element: <ReleaseNotes /> },

    //Dashboard
    {
      path: "/dashboard",
      element: <EmployeeDashboard />,
      // permissionName: PERMISSION_KEYS.DASHBOARD,
    },

    //Document
    {
      path: "/documents",
      element: <Documents />,
      permissionName: PERMISSION_KEYS.DOCUMENT,
    },

    //participant List
    {
      path: "/participants",
      element: <Participants />,
      permissionName: PERMISSION_KEYS.PARTICIPANT,
    },

    //Participant Profile
    {
      path: "/participant-profile/:uuid",
      element: <ParticipantProfile />,
      permissionName: PERMISSION_KEYS.PARTICIPANT,
      permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL,
    },
    // Participant Forms Submitted From worker protal
    {
      path: "/participant-profile/:parentSlug/:slug/view",
      element: <SubmittedSingleFormView />,
    },
    {
      path: "/participant-profile/:parentSlug/:formId/update",
      element: <SubmittedFormUpdate />,
    },

    //CRM View form of Participant's
    {
      path: "/view-template/:ptuuid/:parentSlug/:slug/:isComplete",
      element: <ViewFormTemplate role='crm' />,
    },
    {
      path: "/view-template/:ptuuid/:parentSlug/:slug",
      element: <ViewFormTemplate role='crm' />,
    },

    // Worker HRM View & submit new form and update existing inComplete form
    {
      path: "/view-template/hrm/:ptuuid/:parentSlug/:slug/:isComplete",
      element: <ViewFormTemplate role='worker' />,
      permissionName: PERMISSION_KEYS.SUBMITTED,
      permissionChild:
        BLK_PORTAL_PREMISSION_KEYS_CHILDREN.SUBMITTED.FORM.CREATE,
    },
    {
      path: "/view-template/hrm/:ptuuid/:parentSlug/:slug",
      element: <ViewFormTemplate role='worker' />,
      permissionName: PERMISSION_KEYS.SUBMITTED,
      permissionChild:
        BLK_PORTAL_PREMISSION_KEYS_CHILDREN.SUBMITTED.FORM.CREATE,
    },

    //HRM Submitted Form & All Forms Listing and Update complete form View
    {
      path: "/submitted-forms",
      element: <SubmittedForms />,
      permissionName: PERMISSION_KEYS.SUBMITTED,
      permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.SUBMITTED.SHOW,
    },
    {
      path: "/submitted-form/:parentSlug/:slug/view",
      element: <SubmittedSingleFormView routeRole='worker' />,
      permissionName: PERMISSION_KEYS.SUBMITTED,
      permissionChild: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.SUBMITTED.FORM.VIEW,
    },
    {
      path: "/submitted-form/:parentSlug/:formId/update",
      element: <SubmittedFormUpdate routeRole='worker' />,
      permissionName: PERMISSION_KEYS.SUBMITTED,
      permissionChild:
        BLK_PORTAL_PREMISSION_KEYS_CHILDREN.SUBMITTED.FORM.CREATE,
    },

    //Complaints & Feedback & Policy
    {
      path: "/complaint",
      element: <EmployeeComplaints />,
      permissionName: PERMISSION_KEYS.COMPLAINT,
    },
    {
      path: "/feedback",
      element: <EmployeeFeedback />,
      permissionName: PERMISSION_KEYS.FEEDBACK,
    },
    {
      path: "/ask-a-question",
      element: <AskQuestion />,
      permissionName: PERMISSION_KEYS.ASK_QUESTION,
    },
    {
      path: "/employee-policies",
      element: <EmployeePolicies />,
      permissionName: PERMISSION_KEYS.POLICy,
    },

    //Support coordination
    ...cosRoutes,

    //Sp Standard User Settings
    { path: "/settings", element: <EmployeeSettings /> },
  ],

  sp_cos: [{ path: "*", element: <ComingSoonPage /> }],
};
