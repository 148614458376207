import React, { useEffect, useState, useCallback } from "react";
import SelectOption from "components/common/Select/Select";
import { RECURRING_INTERVAL } from "store/constants";
import CheckboxWithLabel from "components/common/CheckboxWithLabel";
import moment from "moment";

const RecurringShift = ({
  setRecurringType,
  recurringType,
  watchFormData,
  setRecurrInterval,
  recurrInterval,
  setRecurringDates,
  setSelectedDays,
  selectedDays,
  selectedShift,
  setRecurringFor,
  recurringFor,
  handleIntervalChange,
  shiftDetailData,
  setLoading

}) => {


useEffect(() => {
  if (selectedShift !== null && shiftDetailData?.recurring_days) {
    const parsedDays = JSON.parse(shiftDetailData?.recurring_days);
    const formattedDays = {
      mon: parsedDays.monday,
      tue: parsedDays.tuesday,
      wed: parsedDays.wednesday,
      thu: parsedDays.thursday,
      fri: parsedDays.friday,
      sat: parsedDays.saturday,
      sun: parsedDays.sunday,
    };

    if (formattedDays) {
      setSelectedDays(formattedDays);
    }
  }
}, [watchFormData?.shift_date, recurrInterval, recurringType,selectedShift,setSelectedDays,shiftDetailData]);
  useEffect(() => {
    if (watchFormData?.shift_date && selectedShift === null) {
      const shiftDate = moment(watchFormData.shift_date);
      const dayName = shiftDate.format("ddd");
      setSelectedDays((prevSelectedDays) => ({
        ...prevSelectedDays,
        [dayName]: true,
      }));
    }
  }, [watchFormData?.shift_date,recurrInterval,recurringType,selectedShift,setSelectedDays]);


  useEffect(() => {
    if (recurringType) {
      const shiftDate = moment(watchFormData?.shift_date);
      const number = parseInt(recurringType.value.split("_")[0]);
      const label = recurringType.value.split("_")[1];

      const formattedDates = [];

      let intervalFactor = 1;
      if (label === "Week") {
        intervalFactor = 7;
      } else if (label === "Fortnight") {
        intervalFactor = 14; 
      } else if (label === "Month") {
        const daysInMonth = shiftDate.daysInMonth();
        intervalFactor = daysInMonth;
      }

      for (let i = 0; i < number * intervalFactor; i++) {
        const currentDate = shiftDate.clone().add(i, "days");
        const dayName = currentDate.format("ddd");
        if (selectedDays[dayName]) {
          formattedDates.push(currentDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
        }
      }
      setRecurringDates(formattedDates);
    }
  }, [recurringType, watchFormData?.shift_date, selectedDays, setRecurringDates]);

  

  return (
    <div>
      <div className="flex items-center gap-x-3 mt-4">
        <div className="w-[50%]">
          <label className="text-gray-700 text-md font-medium mb-1">
           Please choose recurring interval
          </label>
          <div>
            <SelectOption
              data={RECURRING_INTERVAL}
              placeHolder="Recurring Interval"
              selectedOption={recurrInterval}
              handleChange={handleIntervalChange}
              isSearch={false}
              cssClass="z-8"
              isDisabled={selectedShift !==null}
            />
          </div>
        </div>
        <div className="w-[50%]">
          <label className="text-gray-700 text-md font-medium mb-1">
            Repeat for
          </label>
          <div>
            <SelectOption
              data={recurringFor|| []}
              placeHolder={`select number `}
              selectedOption={recurringType}
              handleChange={(e) => {
                setRecurringType(e);
              }}
              isSearch={false}
              cssClass="z-8"
              isDisabled={selectedShift !==null}
            />
          </div>
        </div>
      </div>
      {watchFormData?.is_recurring && recurringType &&  (
        <div className="mt-6">
          <label className="text-gray-700 text-md font-medium mb-1">
            Please choose the day/days to repeat the shift
          </label>
          <div className="flex flex-row flex-wrap mt-2 w-full gap-3">
            {Object.entries(selectedDays)?.map(([day, isSelected]) => (
              <div key={day} className="flex items-center ">
                <CheckboxWithLabel
                  label={day}
                  checkboxId={day}
                  checked={isSelected}
                  setSelectedDays={setSelectedDays}
                  disabled={selectedShift !==null}
                  // onChange={handleCheckboxChange}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecurringShift;
