import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoClose } from "react-icons/io5";
import { BiChevronRight, BiChevronDown } from "react-icons/bi";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import CustomButton from "components/common/Button/CustomButton";

const MessageModal = ({
  isOpen,
  setCopyShifts,
  reccuringResponseData,
  refreshCalendar,
  handleClose,
  setIsMessage,
  isUserGracePeriodOver,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog
        as='div'
        className='fixed inset-0 z-10 overflow-y-auto flex items-center justify-center'
        onClose={() => {}}
        static
      >
        <div className='flex items-center justify-center w-screen h-screen'>
          <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='relative z-20 bg-white rounded-lg shadow-xl w-[500px]'>
           
              <div className='p-5'>
                {/* <label className='block text-lg font-bold'>
                  {reccuringResponseData?.error?.count +
                    reccuringResponseData?.success?.count}{" "}
                  {reccuringResponseData?.error?.count +
                    reccuringResponseData?.success?.count >
                  1
                    ? "Shifts"
                    : "Shift"}{" "}
                  Created{" "}
                </label> */}
                <label className='block text-lg font-bold'>
                Recurring Shift(s)
                </label>

                {reccuringResponseData?.success?.count !== 0 && (
                  <div className='mt-4 flex items-center gap-x-2'>
                    <FcCheckmark className='w-5 h-5 text-green-500' />
                    <p className='text-md font-semibold text-gray-400'>
                      {/* {reccuringResponseData?.success?.count}{" "} */}
                      {reccuringResponseData?.success?.messages[0]}
                      {/* {reccuringResponseData?.success?.count > 1
                        ? "shifts"
                        : "shift"}{" "}
                      copied successfully */}
                    </p>
                  </div>
                )}

                {reccuringResponseData?.error?.count !== 0 && (
                  <div className='mt-1 flex items-center gap-x-2'>
                    <FcHighPriority className='w-5 h-5 text-red-500' />
                    <p className='text-md font-semibold text-gray-400'>
                      {reccuringResponseData?.error?.count}{" "}
                      {reccuringResponseData?.error?.count > 1
                        ? "Shifts"
                        : "Shift"}{" "}
                      with issues
                    </p>
                  </div>
                )}

                <div className='h-px my-4 bg-gray-300'></div>

                {reccuringResponseData?.error?.count !== 0 && (
                  <div
                    className='mt-4 flex items-center cursor-pointer '
                    onClick={toggleExpanded}
                  >
                    {expanded ? (
                      <BiChevronDown className='w-6 h-6 text-gray-500' />
                    ) : (
                      <BiChevronRight className='w-6 h-6 text-gray-500' />
                    )}
                    <p className='text-md font-semibold text-gray-500'>
                      {reccuringResponseData?.error?.count}{" "}
                      {reccuringResponseData?.error?.count > 1
                        ? "shifts overlapped with existing shifts"
                        : "shift overlapped with existing shift"}
                    </p>
                  </div>
                )}

                {expanded && (
                  <div className='mt-2 pl-8 space-y-1 text-sm text-gray-400 max-h-[200px] custom-scroll overflow-auto'>
                    {reccuringResponseData &&
                      reccuringResponseData?.error?.messages.map(
                        (msg, index) => (
                          <p>
                            {index + 1}- {msg}
                          </p>
                        )
                      )}
                  </div>
                )}
                <div className='text-end w-full mt-4 '>
                  <CustomButton
                    isDisabled={isUserGracePeriodOver}
                    variant={null}
                    cssClass={`cursor-pointer border-none flex font-normal items-center justify-center p-2 bg-blue-500 text-white mr-2`}
                    label='Done'
                    clickHandler={() => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      // handleClose()
                      refreshCalendar({ isRefreshCall: true });
                      setCopyShifts(false);
                      setIsMessage(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MessageModal;
