import React, { useState } from "react";
import SpotLightSearch from "../SpotLight/SpotLight";
import NotificationDropDown from "../NotificationDropDown/NotificationDropDown";
import Search from "../Search/Search";
import CustomButton from "../Button/CustomButton";
import { USER_ROLE } from "../../../Constants/constant";
import { roleAccess } from "../../../helpers/RolesPermission";
// import { useLocation } from "react-router";
import { PERMISSION_KEYS } from "store/constants";
import { classNames } from "Utils/utils";

const Header = ({
  title,
  desc = "",
  isButton = false,
  handleClick,
  btnLabel = "",
  icon,
  btnCss,
  variants,
  btnComponent = null,
  cssClass,
}) => {
  const [searchBox, setSearchBox] = useState(false);
  // const location = useLocation();

  return (
    <header
      className={classNames(
        "bg-white flex items-center sticky top-0 z-[50] border-b border-gray-200 px-4 md:px-6 lg:px-8 py-[20px] h-[80px]",
        cssClass && `${cssClass}`
      )}
    >
      <div className='items-center w-full flex sm:flex sm:items-center sm:justify-between'>
        <div className='flex-1 min-w-0'>
          {/* <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
              {title}
            </h1> */}
          <h1 className='text-xl font-semi bold text-gray-900 capitalize'>
            {title}
            {/* {location && location.pathname.includes("/cos/") && (
              <span className='ml-4 bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300'>
                Beta
              </span>
            )} */}
          </h1>
          <p className='mt-1 text-sm text-gray-500'>{desc}</p>
        </div>
        <div className='mt-4 flex justify-space-between sm:mt-0 space-x-2 print:hidden'>
          <div className='md:mt-0 md:ml-auto space-x-2 md:space-x-4 hidden sm:flex'>
            {isButton && (
              <CustomButton
                clickHandler={handleClick}
                label={btnLabel}
                Icon={icon}
                // variant="btn-purple"
                variant={variants}
                cssClass={btnCss}
              />
            )}
            {btnComponent && <>{btnComponent}</>}
          </div>
          {roleAccess([
            USER_ROLE.Sp_manager,
            USER_ROLE.Sp_Admin,
            USER_ROLE.Sp_Standard_User,
          ]) && (
            <div className='flex justify-end items-center space-x-2'>
              {roleAccess([], PERMISSION_KEYS.PARTICIPANT) && (
                <div>
                  <Search
                    cssClass='w-5 h-5'
                    isSearchOnly={true}
                    handleClick={() => setSearchBox(true)}
                  />
                </div>
              )}
              <div className='flex sm:mt-0 sm:ml-4'>
                <NotificationDropDown />
              </div>
            </div>
          )}
        </div>
      </div>
      {roleAccess([], PERMISSION_KEYS.PARTICIPANT) && searchBox && (
        <SpotLightSearch handleClose={() => setSearchBox(false)} />
      )}
    </header>
  );
};

export default Header;
