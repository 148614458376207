import AvatarGroup from "components/common/Avatar/AvatarGroup";
import ModalSkeleton from "components/Modals/ModalSkeleton";

const DataDisplayModal = ({
  openModel,
  handleClose,
  title = "",
  data = [],
  Participant = [],
  Label = "",
}) => {
  return (
    <ModalSkeleton
      isOpen={openModel}
      title={title}
      cssClass='w-[50%]'
      closeModal={() => handleClose(false)}
    >
      <div className='w-full p-6 bg-white rounded-lg shadow-lg'>
        <div className='bg-blue-100 border border-blue-200 p-4 rounded-md'>
          <label className='text-xl font-semibold text-blue-600'>
            Participant(s):
          </label>
          <span className='block mt-1 text-md text-gray-500 capitalize'>
            {Participant.map((el, index) => (
              <span key={index}>
                {`${el?.first_name} ${el?.last_name}${index < Participant.length - 1 ? ', ' : ''}`}
              </span>
            ))}
          </span>
          {/* Uncomment the following AvatarGroup if needed */}
          {/* <AvatarGroup
            type='participant'
            arrayData={Participant}
            cssClass='mt-0'
            maxLength={5}
            isForRostering
          /> */}
        </div>

        <div className='bg-green-100 border border-green-200 p-4 rounded-md mt-6'>
          {data.length > 0 ? (
            Label === "Shift Notes" ? (
              data.map((item, index) => (
                <div key={index} className='mb-4'>
                  <label className='text-xl font-semibold text-green-600 capitalize'>
                    {Label} for ({item?.first_name} {item?.last_name}):
                  </label>
                  <p className='mt-2 text-md text-gray-500 text-center'>
                    {item.notes || (
                      <span className='text-red-500'>No {Label} available</span>
                    )}
                  </p>
                </div>
              ))
            ) : (
              <>
                <label className='text-xl font-semibold text-green-600'>
                  {Label}:
                </label>
                <p className='mt-2 text-md text-gray-500 text-center'>
                  {data}
                </p>
              </>
            )
          ) : (
            <p className='mt-2 text-md text-gray-500 text-center'>
              <span className='text-red-500'>No {Label} available</span>
            </p>
          )}
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default DataDisplayModal;
