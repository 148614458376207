import React, { useState } from "react";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { ReactMultiEmail } from "react-multi-email";
import { toast } from "react-toastify";

const MultipleEmailModal = ({
  showModal,
  closeModalHandler,
  onClick,
  defaultData = [],
  emailSendLoader,
}) => {
  // const [data, setData] = useState([]);

  const [emails, setEmails] = useState([]);

  // console.log("defaultData", defaultData);

  // useEffect(() => {
  //   setData(defaultData);
  // }, [defaultData]);

  const modalFooter = () => {
    return (
      <>
        <CustomButton
          isDisabled={emailSendLoader}
          variant='danger'
          label='Close'
          clickHandler={() => {
            closeModalHandler();
            setEmails([]);
          }}
        />
        <CustomButton
          showLoading={emailSendLoader}
          isDisabled={emailSendLoader}
          label='Send'
          clickHandler={() => {
            if (emails?.length > 0) {
              onClick(emails);
              // setEmails([]);
            } else {
              toast("Please enter atleast one email!");
            }
          }}
        />
      </>
    );
  };

  return (
    <ModalSkeleton
      isOpen={showModal}
      title={"Send an emails"}
      closeModal={() => {
        closeModalHandler();
        setEmails([]);
      }}
      modalFooter={modalFooter()}
      cssClass='w-[90%] md:w-[50%]'
    >
      <div className='p-2 pt-0'>
        <label className='text-gray-700 text-sm font-medium'>
          Emails (Enter one email, press "Enter" to add another. repeat for more
          emails.)
        </label>

        <div className='py-2'>
          <ReactMultiEmail
            placeholder='Input your emails'
            emails={emails}
            onChange={(_emails) => {
              setEmails(_emails);
            }}
            autoFocus={true}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span
                    className='hover:bg-red-200 p-1'
                    data-tag-handle
                    onClick={() => removeEmail(index)}
                  >
                    ×
                  </span>
                </div>
              );
            }}
          />
        </div>
        <div className='space-y-4'>
          <span className='text-gray-700 text-lg font-semibold'>Logs:</span>
          {defaultData
            ?.filter((i) => i?.email_sent === true)
            ?.map((item, index) => (
              <div className='text-gray-700 text-sm font-medium flex justify-start items-center space-x-2'>
                <span className='w-[5%]'>{index + 1}:</span>
                <span className='w-[85%]'>{item.email_address}</span>
                <span className='w-[10%]'>
                  {item.email_sent ? (
                    <AiOutlineCheckCircle className='w-5 h-5' color={"green"} />
                  ) : (
                    <AiOutlineCloseCircle className='w-5 h-5' color={"red"} />
                  )}
                </span>
              </div>
            ))}
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default MultipleEmailModal;
