import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import { generalActions, rosteringActions } from "../actions";
import api from "../services/rostering.services";
import ACTIONS from "../constants";
import { TOAST_TYPE, PAYROLL_EXPORT_STATUS } from "Constants/constant";
import { parseBooleanVal } from "Utils/utils";

export default function* rootSaga() {
  function* getWorkerAndParticipantList(state) {
    try {
      const pendingPayload = {
        calendarView: state.calendarView,
        search: state.payload.hasOwnProperty("search")
          ? state.payload.search
          : null,
      };
      yield put(
        rosteringActions.workerAndParticipantListfetched.pending(pendingPayload)
      );
      const response = yield call(
        api.getWorkerAndParticipantList,
        state.payload
      );
      if (response) {
        yield put(
          rosteringActions.workerAndParticipantListfetched.success({
            data: response,
            isRefreshCall: state.isRefreshCall || false,
          })
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.workerAndParticipantListfetched.error(
          error.message ?? error
        )
      );
    }
  }
  function* createOrUpdateWorkerShift(state) {
    try {
      yield put(rosteringActions.workerShiftCreatedOrUpdated.pending);
      const response = yield call(
        state.isUpdate ? api.updateWorkerShift : api.createWorkerShift,
        state.payload
      );
console.log(response)
      if (response && response.data) {
        if (response.data.hasOwnProperty("recurring")) {
          yield put(rosteringActions.recurringMessageModal(true));
        } else {
          yield put(
            generalActions.showToast({
              type: TOAST_TYPE.SUCCESS,
              title: `Shift ${
                state.isUpdate ? "Updated" : "Created"
              } Successfully!`,
            })
          );
        }

        yield put(
          rosteringActions.workerShiftCreatedOrUpdated.success({
            response: response.data || [],
            calendarView: state.calendarView,
            isUpdate: state.isUpdate,
            isWorkerChange: state.isWorkerChange,
            prevWorkerUUID: state.prevWorkerUUID,
            recurring: {
              ...response.data.recurring,
            },
          })
        );

        if (state?.callback) {
          state?.callback();
        }
      }
    } catch (error) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.ERROR,
          title: error?.message
            ?.toLowerCase()
            ?.includes("same time already exists")
            ? `${state.name} - ${error?.message}`
            : "Something Went Wrong!",
        })
      );
      yield put(
        rosteringActions.workerShiftCreatedOrUpdated.error(
          error.message ?? error
        )
      );
    }
  }
  // function* createOrUpdateWorkerShift(state) {
  //   try {
  //     yield put(rosteringActions.workerShiftCreatedOrUpdated.pending);
  //     const response = yield call(
  //       state.isUpdate ? api.updateWorkerShift : api.createWorkerShift,
  //       state.payload
  //     );
  //     if (response && response.data) {

  //       // yield put(
  //       //   generalActions.showToast({
  //       //     type: TOAST_TYPE.SUCCESS,
  //       //     title: `Shift ${
  //       //       state.isUpdate ? "Updated" : "Created"
  //       //     } Successfully!`,
  //       //   })
  //       // );

  //       yield put(
  //         rosteringActions.workerShiftCreatedOrUpdated.success({
  //           // response: state.isUpdate ? response?.data?.shift : response?.data,
  //           // response: {
  //           //   ...response?.data || [],
  //           //   // worker_id: response.data[0].worker?.id,
  //           //   // pt_id: response.data[0].participants[0]?.id,
  //           // },
  //           response: state.isUpdate ? [response.data] : response.data || [],
  //           calendarView: state.calendarView,
  //           isUpdate: state.isUpdate,
  //           isWorkerChange: state.isWorkerChange,
  //           prevWorkerUUID: state.prevWorkerUUID,
  //           recurring: {
  //             ...response.data.recurring,
  //           },
  //         })
  //       );

  //       if (response.data.hasOwnProperty("recurring")) {
  //         yield put(rosteringActions.recurringMessageModal(true));
  //       } else{
  //         yield put(
  //           generalActions.showToast({
  //             type: TOAST_TYPE.SUCCESS,
  //             title: `Shift ${
  //               state.isUpdate ? "Updated" : "Created"
  //             } Successfully!`,
  //           })
  //         );
  //       }

  //       // if (!response.data.hasOwnProperty("recurring")) {
  //       //   console.log("in recurring cond")
  //       //   yield put(
  //       //     generalActions.showToast({
  //       //       type: TOAST_TYPE.SUCCESS,
  //       //       title: `Shift ${
  //       //         state.isUpdate ? "Updated" : "Created"
  //       //       } Successfully!`,
  //       //     })
  //       //   );
  //       // } else {
  //       //   yield put(rosteringActions.recurringMessageModal(true));
  //       // }
  //     }
  //   } catch (error) {
  //     yield put(
  //       generalActions.showToast({
  //         type: TOAST_TYPE.ERROR,
  //         title: error?.message
  //           ?.toLowerCase()
  //           ?.includes("same time already exists")
  //           ? `${state.name} - ${error?.message}`
  //           : "Something Went Wrong!",
  //       })
  //     );
  //     yield put(
  //       rosteringActions.workerShiftCreatedOrUpdated.error(
  //         error.message ?? error
  //       )
  //     );
  //   }
  // }

  // function* createOrUpdateWorkerShift(state) {
  //   try {
  //     yield put(rosteringActions.workerShiftCreatedOrUpdated.pending);
  //     const response = yield call(
  //       state.isUpdate ? api.updateWorkerShift : api.createWorkerShift,
  //       state.payload
  //     );
  //     if (response && response.data) {

  //       yield put(
  //         generalActions.showToast({
  //           type: TOAST_TYPE.SUCCESS,
  //           title: `Shift ${
  //             state.isUpdate ? "Updated" : "Created"
  //           } Successfully!`,
  //         })
  //       );

  //       yield put(
  //         rosteringActions.workerShiftCreatedOrUpdated.success({
  //           // response: state.isUpdate ? response?.data?.shift : response?.data,
  //           // response: {
  //           //   ...response?.data || [],
  //           //   // worker_id: response.data[0].worker?.id,
  //           //   // pt_id: response.data[0].participants[0]?.id,
  //           // },
  //           response: state.isUpdate ? [response.data] : response.data || [],
  //           calendarView: state.calendarView,
  //           isUpdate: state.isUpdate,
  //           isWorkerChange: state.isWorkerChange,
  //           prevWorkerUUID: state.prevWorkerUUID,
  //           recurring: {
  //             ...response.data.recurring,
  //           },
  //         })
  //       );

  //       // if (response.data.hasOwnProperty("recurring")) {
  //       //   yield put(rosteringActions.recurringMessageModal(true));
  //       // } else{
  //       //   yield put(
  //       //     generalActions.showToast({
  //       //       type: TOAST_TYPE.SUCCESS,
  //       //       title: `Shift ${
  //       //         state.isUpdate ? "Updated" : "Created"
  //       //       } Successfully!`,
  //       //     })
  //       //   );
  //       // }

  //       // if (!response.data.hasOwnProperty("recurring")) {
  //       //   console.log("in recurring cond")
  //       //   yield put(
  //       //     generalActions.showToast({
  //       //       type: TOAST_TYPE.SUCCESS,
  //       //       title: `Shift ${
  //       //         state.isUpdate ? "Updated" : "Created"
  //       //       } Successfully!`,
  //       //     })
  //       //   );
  //       // } else {
  //       //   yield put(rosteringActions.recurringMessageModal(true));
  //       // }
  //     }
  //   } catch (error) {
  //     yield put(
  //       generalActions.showToast({
  //         type: TOAST_TYPE.ERROR,
  //         title: error?.message
  //           ?.toLowerCase()
  //           ?.includes("same time already exists")
  //           ? `${state.name} - ${error?.message}`
  //           : "Something Went Wrong!",
  //       })
  //     );
  //     yield put(
  //       rosteringActions.workerShiftCreatedOrUpdated.error(
  //         error.message ?? error
  //       )
  //     );
  //   }
  // }

  function* copyWorkerAndParticipantShift(state) {
    try {
      yield put(
        rosteringActions.workerAndParticipantShiftCopied.pending({
          data: { ...state.payload.payload, userId: state.payload.userId },
        })
      );

      const response = yield call(
        api.copyWorkerAndParticipantShift,
        state.payload.payload
      );
      if (response && response.data) {
        yield put(
          rosteringActions.workerAndParticipantShiftCopied.success({
            response: response.data,
            calendarView: state.payload.calendarView,
          })
        );
        yield put(
          generalActions.showToast({
            type: TOAST_TYPE.SUCCESS,
            title: `${response?.message}`,
          })
        );
      }
    } catch (error) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.ERROR,
          title: error?.message ?? "Something Went Wrong!",
        })
      );
      yield put(
        rosteringActions.workerAndParticipantShiftCopied.error(
          error.message ?? error
        )
      );
    }
  }

  function* getWorkerTimesheets(state) {
    try {
      yield put(
        rosteringActions.workerTimeSheetsFetched.pending({
          paginationCall: state.payload.paginationCall,
          isRefreshList: state.isRefreshList,
        })
      );
      const response = yield call(api.getWorkerTimeSheets, state.payload);
      if (response && response.data) {
        yield put(
          rosteringActions.workerTimeSheetsFetched.success(response.data)
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.workerTimeSheetsFetched.error(error.message ?? error)
      );
    }
  }

  function* getParticipantListForTimesheet() {
    try {
      yield put(rosteringActions.participantListForTimeSheet.pending);

      const response = yield call(api.getParticipantListForTimesheet);
      if (response && response.data) {
        yield put(
          rosteringActions.participantListForTimeSheet.success(response.data)
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.participantListForTimeSheet.error(
          error.message ?? error
        )
      );
    }
  }

  function* getTimesheetComments(state) {
    try {
      yield put(
        rosteringActions.timeSheetCommentsFetched.pending(
          state.payload.hasOwnProperty("isFirstCall") &&
            parseBooleanVal(state.payload.isFirstCall)
            ? true
            : false
        )
      );
      const response = yield call(api.getTimesheetComments, state.payload);
      if (response && response.data) {
        yield put(
          rosteringActions.timeSheetCommentsFetched.success(response.data)
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.timeSheetCommentsFetched.error(error.message ?? error)
      );
    }
  }

  function* createTimesheetComment(state) {
    try {
      yield put(rosteringActions.timeSheetCommentCreated.pending);
      const response = yield call(api.createTimesheetComment, state.payload);
      if (response && response.data) {
        yield put(
          rosteringActions.timeSheetCommentCreated.success(response.data)
        );
        yield put(
          generalActions.showToast({
            type: TOAST_TYPE.SUCCESS,
            title: "Comment Created Successfully!",
          })
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.timeSheetCommentCreated.error(error.message ?? error)
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.ERROR,
          title: error?.message ?? "Something Went Wrong!",
        })
      );
    }
  }

  function* updateWorkerTimesheet(state) {
    try {
      yield put(rosteringActions.timeSheetDataUpdated.pending);
      const response = yield call(api.updateTimesheetData, state.payload);
      if (response && response.data) {
        yield put(
          rosteringActions.timeSheetDataUpdated.success({
            data: { ...response.data, comment: state.payload?.data?.comment },
            uuids: state.payload.uuids,
          })
        );
        yield put(
          generalActions.showToast({
            type: TOAST_TYPE.SUCCESS,
            title: "Timesheet Updated Successfully!",
          })
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.timeSheetDataUpdated.error(error.message ?? error)
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.ERROR,
          title: error?.message ?? "Something Went Wrong!",
        })
      );
    }
  }

  function* changeTimesheetStatus(state) {
    try {
      yield put(rosteringActions.timeSheetStatusChanged.pending(state.payload));
      const response = yield call(
        api.changeTimesheetStatus,
        state.payload.data
      );
      if (response.message) {
        yield put(
          rosteringActions.timeSheetStatusChanged.success(state.payload)
        );
        yield put(
          generalActions.showToast({
            type: TOAST_TYPE.SUCCESS,
            title: "Timesheet Status Updated Successfully!",
          })
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.timeSheetStatusChanged.error(error.message ?? error)
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.ERROR,
          title: error?.message ?? "Something Went Wrong!",
        })
      );
    }
  }

  function* getWorkerTimesheetExportList(state) {
    try {
      yield put(rosteringActions.workerTimeSheetExportList.pending);
      const response = yield call(api.workerTimeSheetExportList, state.payload);
      if (response && response.data) {
        yield put(
          rosteringActions.workerTimeSheetExportList.success(response.data)
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.workerTimeSheetExportList.error(error.message ?? error)
      );
    }
  }

  function* getSingleBreakdownTimesheet(state) {
    try {
      yield put(rosteringActions.singleTimesheetDetails.pending);

      const response = yield call(api.singleTimesheetDetails, state.payload);
      if (response && response.data) {
        yield put(
          rosteringActions.singleTimesheetDetails.success(response.data)
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.singleTimesheetDetails.error(error.message ?? error)
      );
    }
  }

  function* exportTimesheetToPayroll(state) {
    try {
      yield put(
        rosteringActions.exportTimesheetToPayroll.pending(state.payload.type)
      );
      const response = yield call(api.exportTimesheetToPayroll, state.payload);
      if (response && response.data) {
        yield put(
          rosteringActions.exportTimesheetToPayroll.success({
            ...response.data,
            payrollType: state.payload.type,
            [`${state.payload.type}_export_status`]:
              response.data.export_status,
          })
        );
        yield put(
          generalActions.showToast({
            type: TOAST_TYPE.SUCCESS,
            title: `Timesheet ${
              response.data.export_status === PAYROLL_EXPORT_STATUS.SUCCESS
                ? "Exported Successfully!"
                : response.data.export_status ===
                  PAYROLL_EXPORT_STATUS.INITIATED
                ? "Scheduled Successfully and might be take 1-2 minutes!"
                : "Exported Successfully!"
            }`,
          })
        );
      }
    } catch (error) {
      yield put(
        rosteringActions.exportTimesheetToPayroll.error({
          error: error.message ?? error,
          payrollType: state.payload.type,
        })
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.ERROR,
          title: error?.message ?? "Something Went Wrong!",
        })
      );
    }
  }

  function* getRosterInvoices(state) {
    try {
      yield put(rosteringActions.getRosterInvoices.pending);

      const response = yield call(api.getRosterInvoicing, state.payload);

      if (response) {
        yield put(rosteringActions.getRosterInvoices.success(response?.data));
      }
    } catch (error) {
      yield put(rosteringActions.getRosterInvoices.error(error));
    }
  }

  yield all([
    takeEvery(
      ACTIONS.FETCH_WORKER_AND_PARTICIPANT_LIST_CALL,
      getWorkerAndParticipantList
    ),
    takeLatest(
      ACTIONS.CREATE_OR_UPDATE_WORKER_SHIFT_CALL,
      createOrUpdateWorkerShift
    ),
    takeLatest(
      ACTIONS.WORKER_AND_PARTICIPANT_SHFIT_COPY_CALL,
      copyWorkerAndParticipantShift
    ),
    takeEvery(ACTIONS.WORKER_TIMESHEETS_CALL, getWorkerTimesheets),
    takeEvery(
      ACTIONS.PARTICIPANT_LIST_FOR_TIMESHEET_CALL,
      getParticipantListForTimesheet
    ),
    takeEvery(ACTIONS.TIMESHEET_COMMENTS_FETCH_CALL, getTimesheetComments),
    takeLatest(ACTIONS.TIMESHEET_COMMENT_CREATE_CALL, createTimesheetComment),
    takeLatest(ACTIONS.TIMESHEET_DATA_UPDATE_CALL, updateWorkerTimesheet),
    takeLatest(ACTIONS.TIMESHEET_STATUS_CHANGE_CALL, changeTimesheetStatus),
    takeEvery(
      ACTIONS.WORKER_TIMESHEET_EXPORT_LIST_CALL,
      getWorkerTimesheetExportList
    ),
    takeLatest(
      ACTIONS.SINGLE_TIMESHEET_DETAILS_CALL,
      getSingleBreakdownTimesheet
    ),
    takeLatest(
      ACTIONS.EXPORT_TIMESHEET_TO_PAYROLL_CALL,
      exportTimesheetToPayroll
    ),
    takeLatest(ACTIONS.GET_ROSTER_INVOICE_CALL, getRosterInvoices),
  ]);
}
