import React, { useCallback, useEffect, useState } from "react";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import moment from "moment";
import { TiExportOutline } from "react-icons/ti";
import { toast } from "react-toastify";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_ROSTERING_WORKERS_TIMESHEET_EXPORT_LIST } from "store/services/URL";

import { useSelector } from "react-redux";
import { getAllShifts } from "store/selector/rostering.selector";
import CheckBox from "components/common/checkBox/checkbox";
import ExportRosterShiftItem from "./ExportRosterShiftItem";
import { capitalizeAllLetters } from "Utils/utils";

const ExportRosterModal = (props) => {
  const { isShow, handleClose,calendarViewValue } = props;
  
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [exportButtonEnabled, setExportButtonEnabled] = useState(false);
  const data = useSelector((state) => getAllShifts(state));

  const [exportDate, setExportDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [displayShiftsData, setDisplayShiftData] = useState([]);


  function capitalizeName(string) {
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  const handleCheckboxChange = (e, uuid, item) => {
    const filteredList = checkboxes.filter((c) => c.uuid !== uuid);
    if (e) {
      filteredList.push({ ...item });
    }
    setCheckboxes([...filteredList]);
    setExportButtonEnabled(filteredList.length > 0);
  };
  const toggleCheckboxes = (val) => {
    if (val) {
      setCheckboxes(data.map((s) => s));
    } else {
      setCheckboxes([]);
    }
    setExportButtonEnabled(val);
  };

  const transformObject = useCallback((obj) => {
    const transformedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        let value = obj[key];
        if (value === "N/A") {
          value = null;
        }
        const newKey = key.toLowerCase().replace(/\s+/g, "_");
        transformedObj[newKey] = value;
      }
    }
    return transformedObj;
  }, []);

  const calendarViewOptions = [
    {
      label: "Worker View",
      value: "worker-view",
    },
    { label: "Participant View", value: "participant-view" },
  ];

  const [calendarView, setCalendarView] = useState(calendarViewOptions[0]);

  useEffect(() => {
    if (moment(exportDate.startDate).isAfter(exportDate.endDate)) {
      setIsLoading(false);
      toast.error("Invalid date range. Please select a valid date range.", {
        toastId: "invalidDateToast",
      });
      return;
    }

    if (
      exportDate.startDate &&
      exportDate.endDate &&
      !moment(exportDate.startDate).isAfter(exportDate.endDate)
    ) {
      setIsLoading(true);
      const start_date = exportDate?.startDate;
      const end_date = exportDate?.endDate;

      apiPostRequest(URL_ROSTERING_WORKERS_TIMESHEET_EXPORT_LIST, {
        start_date,
        end_date,
      })
        .then((res) => {
          const data = res?.data?.data;

          const dataset = [];
          data?.forEach((timeSheet) => {
            const workerName =
              timeSheet?.first_name + " " + timeSheet?.last_name;
            timeSheet?.timeSheet?.forEach((timesheetItem) => {
          
              const participantNames = timesheetItem?.participants
                .map(
                  (participant) =>
                    participant?.first_name + " " + participant?.last_name
                )
                .join(", ");

              const shiftNotes = timesheetItem?.note || "";
              const shiftLength = timesheetItem?.timesheet_length;
              const shiftStartTime = timesheetItem?.start_time;
              const shiftEndTime = timesheetItem?.end_time;
              const shiftDate = timesheetItem?.time_sheet_date;
              const workerStartTime = timesheetItem?.worker_start_time;
              const workerEndTime = timesheetItem?.worker_end_time;

              dataset.push({
                "Participant Name": participantNames,
                "Worker Name": workerName,
                "Shift Notes": shiftNotes ? shiftNotes : "N/A",
                "Shift Date": shiftDate ? shiftDate : "N/A",
                "Shift Length": shiftLength,
                "Shift Start Time": shiftStartTime ? shiftStartTime : "N/A",
                "Shift End Time": shiftEndTime ? shiftEndTime : "N/A",
                "Worker Start Time": workerStartTime ? workerStartTime : "N/A",
                "Worker End Time": workerEndTime ? workerEndTime : "N/A",
              });
            });
          });

          const transformedArray = dataset.map((obj) => transformObject(obj));
          setDisplayShiftData(transformedArray);
          setCsvData(dataset);
          setIsLoading(false);
        })
        .catch((error) => console.log({ error }));
    }
  }, [exportDate.startDate, exportDate.endDate]);

//   const handleExportCSV = () => {
//     if (checkboxes.length === 0) {
//       return; // No checkbox is selected, do nothing
//     }

//     const csvContent = [];
//     const csvLink = document.createElement("a");

//     // Columns to exclude
//     const excludedColumns = [
//       "id",
//       "uuid",
//       "Shift Notes",
//       "sp_id",
//       "pt_id",
//       "worker_id",
//       "hours",
//       "minutes",
//       "tasks",
//       "timezone",
//       "type",
//       "progress",
//       "is_group_shift",
//       "is_next_day_shift",
//       "created_at",
//       "updated_at",
//       "deleted_at",
//       "rejection_note",
//       "start_lng",
//       "end_lng",
//       "location_tracking",
//       "pt_sign",
//       "is_signature",
//       "participant",
//       "worker",
//       "participants",
//       "start_lat",
//       "end_lat",
//       "invoice_service_type_id",
//       "sleepover",
//       "ratio",
//     ];

//     const desiredHeaders = [
//       "participant_name",
//       "worker_name",
//       "status",
//       "shift_date",
//       "shift_length",
//       "start_time",
//       "end_time",
//       "worker_start_time",
//       "worker_end_time",
//       "note",
//     ];

//     csvContent.push(desiredHeaders);
//     csvContent[0].push("Shift Notes");

//     checkboxes.forEach((item) => {
//       const rowDataWithoutExcludedColumns = desiredHeaders.map((header) => {
//         let value = item[header] ? item[header] : "N/A"; 
//         if (value === null || value === undefined) {
//           value = "N/A"; 
//         }
//         if (header === "participant_name") {
//           if(calendarViewValue === "worker-view"){
//             return item["participants"]
//             ?.map((x) => capitalizeAllLetters(`${x.first_name} ${x.last_name}`))
//             ?.join(",");
//           } else if(calendarViewValue === "participant-view" && item && item["participant_name"]){
            
//             return capitalizeName(item["participant_name"])
//           }
         
//         }
//         return value;
//       });
// console.log({item},rowDataWithoutExcludedColumns)
// // Adding the "Shift Notes" header to the CSV


// // Prepare shift notes as a single formatted string with newline separators
// const shiftNotes = item?.timesheets?.shift_notes?.length > 0 
//   ? item.timesheets.shift_notes.map((singleShiftNote) => 
//       `Shift Notes for (${singleShiftNote?.participant?.first_name} ${singleShiftNote?.participant?.last_name})\n${singleShiftNote.notes}`
//     ).join("\n\n") // Separate multiple notes with a double newline
//   : "N/A"; // Display "N/A" if there are no shift notes

// // Add the shift notes to the row data
// rowDataWithoutExcludedColumns.push(shiftNotes);

// // Push the row data to the CSV content
// csvContent.push(rowDataWithoutExcludedColumns);

    
      
//       console.log(csvContent)
//     });

//     const csvContentStr = csvContent
//       .map((row) =>
//         row.map((cell) => (cell.includes(",") ? `"${cell}"` : cell)).join(",")
//       )
//       .join("\n");
//     const blob = new Blob([csvContentStr], { type: "text/csv;charset=utf-8;" });
//     const url = URL.createObjectURL(blob);
//     csvLink.setAttribute("href", url);
//     csvLink.setAttribute("download", "Export Roster.csv");
//     csvLink.style.visibility = "hidden";
//     document.body.appendChild(csvLink);
//     csvLink.click();
//     document.body.removeChild(csvLink);

//     handleClose();
//   };

  // const handleCancelButton = (date) => {
  //   if (date === "startDate") {
  //     setExportDate((prev) => ({
  //       ...prev,
  //       startDate: null,
  //     }));
  //   } else {
  //     setExportDate((prev) => ({
  //       ...prev,
  //       endDate: null,
  //     }));
  //   }
  //   setDisplayShiftData([]);
  // };
  // const modalFooter = () => (
  //   <>
  //     <CustomButton
  //       variant='danger'
  //       clickHandler={() => {
  //         handleClose();
  //       }}
  //       label='Cancel'
  //     />
  //     <CustomButton
  //       clickHandler={handleExportCSV}
  //       showLoading={isLoading}
  //       isDisabled={!exportDate.startDate || !exportDate.endDate}
  //       label={"Export"}
  //       id='export_csv'
  //       Icon={<TiExportOutline className='h-5 w-5' />}
  //     />
  //   </>
  // );



  const handleExportCSV = () => {
    if (checkboxes.length === 0) {
      return; // No checkbox is selected, do nothing
    }
  
    const csvContent = [];
    const csvLink = document.createElement("a");
  
    // Define headers excluding Shift Notes
    const desiredHeaders = [
      "participant_name",
      "worker_name",
      "status",
      "shift_date",
      "shift_length",
      "start_time",
      "end_time",
      "worker_start_time",
      "worker_end_time",
      "note",
    ];
  
    // Push the headers including Shift Notes
    csvContent.push([...desiredHeaders, "Shift Notes"]);
  
    checkboxes.forEach((item) => {
      const rowDataWithoutExcludedColumns = desiredHeaders.map((header) => {
        let value = item[header] ? item[header] : "N/A";
        if (value === null || value === undefined) {
          value = "N/A";
        }
        if (header === "participant_name") {
          if (calendarViewValue === "worker-view") {
            return item["participants"]
              ?.map((x) => capitalizeAllLetters(`${x.first_name} ${x.last_name}`))
              ?.join(",");
          } else if (calendarViewValue === "participant-view" && item && item["participant_name"]) {
            return capitalizeName(item["participant_name"]);
          }
        } else if (header === "shift_date" && value !== "N/A") {
          return moment(value).format("DD/MM/YYYY"); 
        } else if ((header === "start_time" || header === "end_time") && value !== "N/A") {
          return moment(value).format("DD/MM/YYYY HH:mm:ss"); 
        }
        return value;
      });
  
    
      const shiftNotes = item?.timesheets?.shift_notes?.length > 0 
        ? item.timesheets.shift_notes.map((singleShiftNote) => 
            `Shift Notes for (${singleShiftNote?.participant?.first_name} ${singleShiftNote?.participant?.last_name}): ${singleShiftNote.notes.replace(/\n/g, " ")}`
          ).join("\n")  
        : "N/A"; 
  
      rowDataWithoutExcludedColumns.push(shiftNotes);
  
      csvContent.push(rowDataWithoutExcludedColumns);
    });
  
    const csvContentStr = csvContent
      .map((row) =>
        row.map((cell) => (cell.includes(",") || cell.includes("\n") ? `"${cell}"` : cell)).join(",")
      )
      .join("\n");
  
    const blob = new Blob([csvContentStr], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    csvLink.setAttribute("href", url);
    csvLink.setAttribute("download", "Export_Roster.csv");
    csvLink.style.visibility = "hidden";
    document.body.appendChild(csvLink);
    csvLink.click();
    document.body.removeChild(csvLink);
  
    handleClose();
  };
  
  
  
  const rosterShiftContent = () => (
    <>
      {/* <div className='flex items-center gap-4 w-full mb-7'>
        <div className='p-4 py-0'>
          <label className='text-sm text-gray-700 font-medium leading-5'>
            View
          </label>
          <SelectOption
            cssClass=''
            isSearch={false}
            selectedOption={calendarView}
            handleChange={setCalendarView}
            data={calendarViewOptions}
          />
        </div>
      </div> */}
      <div className='min-w-full p-4 py-0'>
        <div className='min-w-full p-4 py-0'>
          {data && data.length > 0 && (
            <div className='py-2'>
              <h4>
                <b>Total Shifts:</b> {data.length}
              </h4>
            </div>
          )}
        </div>
        {/* Add margin-bottom here */}
        <div className='overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mb-4'>
          <table className='min-w-full divide-y divide-gray-300 border'>
            <thead className='capitalize'>
              <tr>
                <th
                  scope='col'
                  className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50 w-[10%]`}
                >
                  <CheckBox
                    checked={allSelected}
                    handleChange={(e) => {
                      if (data && data.length > 0) {
                        setAllSelected(e);
                        toggleCheckboxes(e);
                      }
                    }}
                  />
                </th>
                <TableHeader isSort={false} label={"Worker name"} />
                <TableHeader isSort={false} label={"Participant name"} />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Shift date"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Shift Status"}
                ></TableHeader>
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Shift length"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Shift start time"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Shift end time"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Worker start time"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Worker end time"}
                />
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {false ? (
                <tr>
                  <td colSpan={7} className='bg-gray-50 py-2'>
                    <CircularLoader
                      classes='flex justify-center items-center'
                      classloader='loader-l'
                    />
                  </td>
                </tr>
              ) : data && data.length > 0 ? (
                data.map((shift, index) => (
                  <ExportRosterShiftItem
                    shift={shift}
                    handleCheckboxChange={(e) =>
                      handleCheckboxChange(e, shift.uuid, shift)
                    }
                    checkboxes={checkboxes}
                    key={index}
                    calendarView={calendarView}
                  />
                ))
              ) : (
                data.length === 0 && (
                  <tr>
                    <td colSpan={9} className='py-6 text-center text-red-600'>
                      No Data Found !
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
  const exportButton = (
    <CustomButton
      clickHandler={handleExportCSV}
      isDisabled={!exportButtonEnabled}
      label='Export'
      Icon={<TiExportOutline className='h-5 w-5' />}
    />
  );

  return (
    <div>
      {isShow && (
        <ModalSkeleton
          title='Export Roster'
          isOpen={isShow}
          cssClass='w-[60%] '
          bodyPadding='px-0'
          closeModal={handleClose}
          modalFooter={[
            // Render export button conditionally
            <CustomButton
              key='cancelButton'
              variant='danger'
              clickHandler={handleClose}
              label='Cancel'
            />,
            exportButton,
          ]}
        >
          {false ? (
            <div
              className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
            >
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            // <div className='overflow-y-auto max-h-[600px] custom-scroll'>
            <div>
              {" "}
              {rosterShiftContent()}
            </div>
          )}
        </ModalSkeleton>
      )}
    </div>
  );
};

export default ExportRosterModal;
