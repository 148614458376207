import _, { isArray } from "lodash";
import moment from "moment";
import {
  COUNT_ABBRS,
  emptyPaginationObj,
  modifiedObj,
  portalOption,
  DUMMY_REGEX_EXP,
  USER_ROLE,
  PARTICIPANT_PROFILE_TABS,
  PARENT_FORM_CATEGORY_SLUG,
  USER_ROLE_TITLE,
  TIMESHEET_PROGRESS_STATUS,
  SHIFT_STATUS,
  PAYROLL_EXPORT_STATUS,
  MIME_TYPES,
} from "../Constants/constant";
import GetItemFromLocalstorage from "./GetItemFromLocalstorage";
import { roleAccess } from "helpers/RolesPermission";
import { IMAGE_URL } from "store/services/URL";
import * as FileSaver from "file-saver";
import { PAYROLL_PLATFORMS } from "store/constants";
import store from "../../src/store/index";
import momentTimeZone from "moment-timezone";
import XLSX from "sheetjs-style";
import api from "store/services/sp.services";

export const convertLabelIntoName = (value) => {
  if (!value) return "";
  const data = value
    .replace(/[^a-zA-Z ]/g, "")
    .toLowerCase()
    .split(" ")
    .join("_");

  return data && data.length > 0 && data.charAt(data.length - 1) === "_"
    ? data.slice(0, -1)
    : data;
};

export const currentDateForValidation = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  } else {
    month = `${month}`;
  }
  let day = date.getDate();
  let completeDate = `${year}-${month}-${day}`;
  return completeDate;
};

export const convertClientDataToPrint = (array1) => {
  const UpdatedList = array1.map((item) => {
    return {
      Participant: item?.participant,

      Service: item?.delivered_service_number,
      //  'Service Region'
      "Service Date": item?.delivered_date,

      "Total Service Hour": (item?.hours)
        .toString()
        .concat(":", (item?.mints).toString()),

      // 'Total Service KMs'
      Rate: item?.original_rate,

      Total: item?.total_amount,

      Status: item?.invoice_status,
    };
  });
  return UpdatedList;
};

export const convertCosDataToPrint = (array1) => {
  const UpdatedList = array1.map((item) => {
    return {
      "Support Coordination": item?.support_category_name,
      Service: item?.delivered_service_number,
      "Service Date": item?.delivered_date,
      "Total Service Hour": (item?.hours)
        .toString()
        .concat(":", (item?.mints).toString()),
      // 'Total Service KMs'
      Rate: item?.original_rate,
      Total: item?.total_amount,
    };
  });
  return UpdatedList;
};

export const getReportData = (reportData) => {
  if (reportData && reportData.length > 0) {
    const newList = reportData.map((item) => {
      return {
        ID: item.id,
        Participant: item?.participant?.first_name.concat(
          " ",
          item?.participant?.last_name
        ),
        "Support Item Number": item?.price_catalog?.support_item_number,
        "Support item name": item?.price_catalog?.support_item_name,
        "Service subcategory": item?.service_sub_category?.title,
        "DS-number": item?.delivered_service_number,
        Status: item?.status,
        Unit: item?.unit,
        Quantity:
          item?.unit === "H" ? item?.hours + ":" + item?.mints : item?.quantity,
        "Total amount": item?.total_amount,
        "Bill types": item?.bill_types,
        "Created at": item?.created_at,
      };
    });
    return newList;
  }
};

export const getDifference = (array1, array2) => {
  return array1.filter((object1) => {
    return array2.some((object2) => {
      return object1.value === object2.value;
    });
  });
};

export const isValidFile = (file, type) => {
  if (!file?.name) return "";
  const fileName = file.name.toLowerCase();
  const exts = getFileExtensions(type);
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex <= 0) return "";
  const fileExtension = fileName.slice(lastDotIndex + 1);
  if (!exts.includes(fileExtension)) {
    const errorMessages = {
      any: "only Pdf/Doc/Excel/Image files are allowed",
      casenote: "only Pdf/Doc/Image files are allowed",
      resume: "only Pdf/Doc files are allowed",
      image: "only Image files are allowed",
      default: "only Pdf files are allowed",
    };
    return errorMessages[type] || errorMessages.default;
  }

  return "";
};

export const maxFileSize = (file, size) => {
  if (file.size === 0) return `file size 0 byte please upload another one`;
  if (file.size / (1024 * 1024).toFixed(2) > size) {
    return `Maximum file size allowed is ${size}MB.`;
  }
  return "";
};

export const getFileSize = (file) => {
  if (!file) return "";
  return `${(file.size / (1024 * 1024)).toFixed(2)}MB`;
};

export const getFileExtensions = (type) => {
  let exts = "";
  if (type === "any") {
    exts = ["png", "jpg", "jpeg", "gif", "pdf", "doc", "docx", "xlsx", "csv"];
  } else if (type === "resume") {
    exts = ["pdf", "doc", "docx"];
  } else if (type === "casenote") {
    exts = ["pdf", "doc", "docx", "png", "jpg", "jpeg", "gif", "zip"];
  } else {
    exts = type === "image" ? ["png", "jpg", "jpeg", "gif"] : ["pdf"];
  }
  return exts;
};

export const baseURLImage = (url) => {
  if (!url) return "";
  return `${IMAGE_URL}${url}`;
};

export const getNameProfile = (first, last, background = null) => {
  if (!first || !last) return "";
  return `https://ui-avatars.com/api/?name=${first}+${last}&background=${
    background ? background : "random"
  }`;
};

export const hasBlankSpaces = (str) => {
  if (!str) return false;
  return str.match(/^\s+$/) !== null;
};

export const parseBooleanVal = (val) => {
  if (val === "yes" || val === "true" || val === true) return true;
  return false;
};

export const getPaginationObj = (data, query = null) => {
  if (!data && !query) return emptyPaginationObj;
  const obj = {
    page: data.current_page || data.page,
    totalPages: data.total_pages || data.totalPages,
    total: data.total,
  };
  if (query) {
    Object.assign(obj, {
      sort_field: query.sort_field ?? null,
      sort_order: query.sort_order ?? null,
      filter: query.filter ?? null,
      search: query.search.length === 0 ? null : query.search,
    });
  }
  return obj;
};

export const selectedTabState = {
  save: (path) => sessionStorage.setItem("currentTab", path),
  get: () =>
    sessionStorage.getItem("currentTab")
      ? JSON.parse(sessionStorage.getItem("currentTab"))
      : null,
};

export function sleep(ms = 650) {
  console.log("Kindly remember to remove `sleep`");
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getAge(dateString) {
  if (!dateString || dateString === undefined) return "";
  const date = moment(dateString, "YYYY-MM-DD");
  const years = moment().diff(date, "years");
  const days = moment().diff(date.add(years, "years"), "days", false);
  return { years, days };
}

export const futureDateValidation = (
  date,
  addDaysOrMonth,
  momentAdd,
  fromDate = null,
  isRegex = false
) => {
  const currentDate = fromDate ? fromDate : moment();
  if (isRegex === false && moment(date).isBefore()) return true;
  const afterDate = moment(currentDate).add(addDaysOrMonth, momentAdd);
  const diff = afterDate.diff(date, "days");
  return diff <= 0 ? false : isRegex === true ? DUMMY_REGEX_EXP : true;
};

export const getExtension = (filename) => {
  const fileNameLength = filename.split("/");
  return filename
    ? filename.split("/")[fileNameLength.length - 1]
    : "sample.pdf";
};

export const getExtensionName = (fileName) => {
  const lastDotIndex = fileName?.lastIndexOf(".");
  return lastDotIndex > 0
    ? `View ${fileName.slice(lastDotIndex + 1).toUpperCase()}`
    : "No File";
};

export const getFileExtension = (fileName) => {
  if (!fileName) return null;
  const lastDotIndex = fileName.lastIndexOf(".");
  return lastDotIndex > 0 ? fileName.slice(lastDotIndex + 1) : null;
};

export const getDynamicFormSelectValue = (editData, key) => {
  return editData
    ? portalOption.find(
        (x) => x.value === (editData[key] === true ? "yes" : "no")
      )
    : key === "required"
    ? portalOption[0]
    : portalOption[1];
};

export const dynamicFormDateValidation = (
  field,
  watchFormData,
  templateIndex,
  inputFieldIndex,
  subInputFieldIndex = null,
  isRegex = true
) => {
  let dates = null;
  const currentDate = moment(new Date());
  let fieldValueDate = null;

  if (field && field.hasOwnProperty("dateValidation")) {
    dates = field.dateValidation.map((x) => x.value);
  } else {
    return false;
  }
  if (Object.keys(watchFormData).length === 0) {
    return false;
  }
  if (
    dates &&
    dates.length > 0 &&
    watchFormData &&
    templateIndex >= 0 &&
    inputFieldIndex >= 0 &&
    watchFormData.template[templateIndex] !== undefined &&
    watchFormData.template[templateIndex]
  ) {
    if (
      subInputFieldIndex === null &&
      watchFormData.template[templateIndex].input_fields[inputFieldIndex] !==
        undefined &&
      watchFormData.template[templateIndex].input_fields[inputFieldIndex] &&
      `${convertLabelIntoName(field.lable)}` in
        watchFormData.template[templateIndex].input_fields[inputFieldIndex]
    ) {
      fieldValueDate = moment(
        watchFormData.template[templateIndex].input_fields[inputFieldIndex][
          convertLabelIntoName(field.lable)
        ]
      );
    } else if (
      subInputFieldIndex !== null &&
      watchFormData.template[templateIndex].input_fields[inputFieldIndex] &&
      watchFormData.template[templateIndex].input_fields[inputFieldIndex]
        .sub_section &&
      watchFormData.template[templateIndex].input_fields[inputFieldIndex]
        .sub_section[subInputFieldIndex] !== undefined &&
      watchFormData.template[templateIndex].input_fields[inputFieldIndex]
        .sub_section[subInputFieldIndex] &&
      `${convertLabelIntoName(field.lable)}` in
        watchFormData.template[templateIndex].input_fields[inputFieldIndex]
          .sub_section[subInputFieldIndex]
    ) {
      fieldValueDate = moment(
        watchFormData.template[templateIndex].input_fields[inputFieldIndex]
          .sub_section[subInputFieldIndex][convertLabelIntoName(field.lable)]
      );
    }
    const response = validateCurrentDate(
      fieldValueDate,
      currentDate,
      dates,
      isRegex
    );
    return response;
  }
  return DUMMY_REGEX_EXP;
};

export const getUrlRole = () => {
  const user = GetItemFromLocalstorage("user");
  if (user && roleAccess([USER_ROLE.Sp_Standard_User])) {
    return "hrm";
  } else if (user && roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) {
    return "sp";
  }
  return null;
};

export const textTruncate = (
  str,
  isGreaterThanRequiredCharacters,
  returnCharactersNeeded
) => {
  if (!str) return "";
  return str.length > isGreaterThanRequiredCharacters
    ? str.substring(0, returnCharactersNeeded) + "..."
    : str;
};

export const convertLabel = (arr) => {
  const newArr = arr.map((some) => {
    return {
      name: some.name.charAt(0).toUpperCase() + some.name.slice(1) || "",
      label: some.name.charAt(0).toUpperCase() + some.name.slice(1) || "",
      value: some.name.charAt(0).toUpperCase() + some.name.slice(1) || "",
      id: some.id || "",
    };
  });
  newArr.unshift({ label: "All", id: "all", value: "all", name: "all" });
  return newArr;
};

export const convertLabelToSelect = (arr) => {
  const newArr = arr.map((some) => {
    return {
      id: some.uuid,
      name: `${some.first_name} ${some.last_name}`,
      label: `${some.first_name} ${some.last_name}`,
      value: `${some.first_name} ${some.last_name}`,
    };
  });
  return [modifiedObj, ...newArr];
};

export const NumberWithInternationalSign = (
  count,
  withAbbr = false,
  decimals = 0
) => {
  const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  if (i.toString().includes("Infinity")) return count;
  let result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
  if (withAbbr) {
    result += `${COUNT_ABBRS[i]}`;
  }
  return result;
};

export const validDate = (date) => {
  let dateformat = /^(0?[1-9]|[1-2][0-9]|3[01])[\/](0?[1-9]|1[0-2])/;

  // Matching the date through regular expression
  if (date.match(dateformat)) {
    let operator = date.split("/");

    // Extract the string into month, date and year
    let datepart = [];
    if (operator.length > 1) {
      datepart = date.split("/");
    }
    let day = parseInt(datepart[0]);
    let month = parseInt(datepart[1]);
    let year = parseInt(datepart[2]);

    // Create a list of days of a month
    let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (month === 1 || month > 2) {
      if (day > ListofDays[month - 1]) {
        // console.log("Invalid date");
        return false;
      }
    } else if (month === 2) {
      let leapYear = false;
      if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
      if (leapYear === false && day >= 29) {
        // console.log("Invalid date");
        return false;
      } else if (leapYear === true && day > 29) {
        // console.log("Invalid date format!");
        return false;
      }
    }
  } else {
    // console.log("Invalid date format!");
    return false;
  }
  return true;
};

export const gethoursAndMins = (hr = 0, min = 0) => {
  let ttl = "";
  if (typeof hr === "string" && typeof min === "string") {
    return `${hr}:${min}`;
  }
  if (hr < 10) {
    ttl = `0${hr}:`;
  } else {
    ttl = `${hr}:`;
  }
  if (min < 10) {
    ttl = `${ttl}0${min}`;
  } else {
    ttl = `${ttl}${min}`;
  }
  return ttl;
};

export const getFormSubTabForParticipantProfile = (parentSlug) => {
  if (!parentSlug) return false;
  let parentTabForm = "";
  if (parentSlug !== PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG) {
    parentTabForm = PARTICIPANT_PROFILE_TABS.find((x) => x.id === 4);
    if (
      parentTabForm &&
      parentTabForm.children &&
      parentTabForm.children.length > 0
    ) {
      const subMenuSlug = parentTabForm.children.find(
        (x) => x.slug && x.slug === parentSlug
      );
      return subMenuSlug ? subMenuSlug : false;
    }
  } else if (parentSlug === PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG) {
    parentTabForm = PARTICIPANT_PROFILE_TABS.find((x) => x.id === 11);
    return parentTabForm;
  }

  return false;
};

export const changeDateToYearMonthDay = (date) => {
  if (!date) return;
  return moment(date).format("YYYY-MM-DD");
};

export const convertStringyfyIntoJSON = (valueInStringyfy) => {
  let JsonData = valueInStringyfy;
  const parseInJson = (data) => {
    if (data) {
      if (typeof data === "string") {
        return JSON.parse(data);
      } else {
        return data;
      }
    }
    return null;
  };

  if (JsonData) {
    do {
      JsonData = parseInJson(JsonData);
    } while (typeof parseInJson(JsonData) === "string");
  }

  return JsonData ? JsonData : [];
};

export const capitalizeFirstLetter = (string) => {
  if (!string || string === undefined) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeAllLetters = (string) => {
  if (!string || string === undefined) return "";
  return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const checkPasswordValidity = (value) => {
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value)) {
    return "Password must not contain Whitespaces.";
  }

  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
  if (!isContainsUppercase.test(value)) {
    return "Password must have at least one Uppercase Character.";
  }

  const isContainsLowercase = /^(?=.*[a-z]).*$/;
  if (!isContainsLowercase.test(value)) {
    return "Password must have at least one Lowercase Character.";
  }

  const isContainsNumber = /^(?=.*[0-9]).*$/;
  if (!isContainsNumber.test(value)) {
    return "Password must contain at least one Digit.";
  }

  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
  if (!isContainsSymbol.test(value)) {
    return "Password must contain at least one Special Symbol.";
  }

  const isValidLength = /^.{8,16}$/;
  if (!isValidLength.test(value)) {
    return "Password must be 8-16 Characters Long.";
  }

  return null;
};

export const getUserTypeFromLC = () => {
  return localStorage.getItem("userType")
    ? localStorage.getItem("userType")
    : null;
};

export const getUserRoleTitle = (role) => {
  if (!role) return "";
  return USER_ROLE_TITLE[role];
};

export const validateCurrentDate = (
  fieldValueDate,
  currentDate,
  dates,
  isRegex
) => {
  if (!Array.isArray(dates)) return DUMMY_REGEX_EXP;
  if (
    dates.find((x) => x === "future") &&
    dates.find((x) => x === "past") &&
    dates.find((x) => x === "present") &&
    fieldValueDate
  ) {
    return false;
  }
  if (
    dates.find((x) => x === "past") &&
    dates.find((x) => x === "present") &&
    fieldValueDate
  ) {
    const isValid = currentDate.isSameOrBefore(fieldValueDate);
    if (!isValid) return false;
    return isValid && isRegex
      ? DUMMY_REGEX_EXP
      : "Date should not be greater than today";
  }
  if (
    dates.find((x) => x === "future") &&
    dates.find((x) => x === "present") &&
    fieldValueDate
  ) {
    const isValid = fieldValueDate.diff(currentDate, "days");
    if (isValid >= 0) return false;
    return isValid < 0 && isRegex
      ? DUMMY_REGEX_EXP
      : "Date should not be less than today";
  }
  if (
    dates.find((x) => x === "future") &&
    dates.find((x) => x === "past") &&
    fieldValueDate
  ) {
    const isValid = moment(currentDate).isSame(moment(fieldValueDate), "day");
    if (!isValid) return false;
    return isValid && isRegex
      ? DUMMY_REGEX_EXP
      : "Date should not be today date";
  }
  if (dates.find((x) => x === "past") && fieldValueDate) {
    const isValid = currentDate.diff(fieldValueDate, "days");
    if (isValid > 0) return false;
    return isValid <= 0 && isRegex
      ? DUMMY_REGEX_EXP
      : "Date should not be equal or greater than today";
  }
  if (dates.find((x) => x === "present") && fieldValueDate) {
    const isValid = moment(currentDate).isSame(moment(fieldValueDate), "day");
    if (isValid) return false;
    return !isValid && isRegex
      ? DUMMY_REGEX_EXP
      : "Date should not be less or greater than today";
  }
  if (dates.find((x) => x === "future") && fieldValueDate) {
    const isValid = fieldValueDate.isAfter(currentDate, "days");
    if (isValid) return false;
    return !isValid && isRegex
      ? DUMMY_REGEX_EXP
      : "Date should not be equal or less than today";
  }
  return DUMMY_REGEX_EXP;
};

export const formatDateIntoDMY = (date) => {
  if (!date) return "";
  return moment(date).format("DD-MM-YYYY");
};

export const formatDateIntoDMYToDisplay = (date, format = "DD-MMM-YYYY") => {
  if (!date) return "";
  return moment(date).format(format);
};

export const formatDateIntoDMYHMS = (date) => {
  if (!date) return "";
  // const currentTimeZone = momentTimeZone.tz.guess();
  // const dateVal = momentTimeZone(date).tz(currentTimeZone);
  const dateWithoutTAndTimezone = date.replace("T", " ").substring(0, 19);
  return moment(dateWithoutTAndTimezone).format("DD-MM-YYYY, h:mm:ss A");
};

export const formatDateIntoDMMMY = (date) => {
  if (!date) return "";
  return moment(date).format("Do MMM YYYY");
};
export const formatDateIntoDMM = (date) => {
  if (!date) return "";
  return moment(date).format("Do MMM");
};

export const getError = (
  errors,
  inputFieldLabel,
  index = null,
  inputIndex = null,
  subInputIndex = null
) => {
  if (
    inputFieldLabel &&
    !isNaN(index) &&
    !isNaN(inputIndex) &&
    subInputIndex === null
  ) {
    return (
      errors?.template?.[index]?.input_fields?.[inputIndex]?.[
        `${convertLabelIntoName(inputFieldLabel)}`
      ]?.type === "required" && (
        <span className='text-sm text-red-500'>This field is required!</span>
      )
    );
  }
  if (
    inputFieldLabel &&
    !isNaN(index) &&
    !isNaN(inputIndex) &&
    !isNaN(subInputIndex)
  ) {
    return (
      errors?.template?.[index]?.input_fields?.[inputIndex]?.sub_section[
        subInputIndex
      ]?.[`${convertLabelIntoName(inputFieldLabel)}`]?.type === "required" && (
        <span className='text-sm text-red-500'>This field is required!</span>
      )
    );
  }
  return null;
};

export const getPlanManagedBy = (data) => {
  if (!data) return "-";
  const val = convertStringyfyIntoJSON(data);
  if (val && isArray(val) && val.length > 0) {
    return val.map((x) => x?.label).join(", ");
  } else if (val && !isArray(val)) {
    return val.hasOwnProperty("label") ? val.label : "-";
  }
  return "-";
};

export const getShiftLength = (
  start_time,
  end_time,
  Format_HH_MM = false,
  isCheckValidate = false
) => {
  if (
    start_time &&
    start_time !== null &&
    end_time &&
    end_time !== null &&
    end_time !== undefined
  ) {
    const time1 = moment(start_time, "HH:mm");
    const time2 = moment(end_time, "HH:mm");

    let difference = 0;

    if (time2.isBefore(time1)) {
      time2.add(1, "day");
    }

    difference = time2.diff(time1, "minutes");

    const hours = Math.floor(difference / 60);
    const remainingMinutes = difference % 60;

    const time = gethoursAndMins(hours, remainingMinutes);
    if (isCheckValidate === true) {
      return time && time.includes(":")
        ? `${time.split(":")[0]}:${time.split(":")[1]}` !== "00:00"
        : false;
    }
    if (Format_HH_MM) {
      return time && time.includes(":")
        ? `${time.split(":")[0]}:${time.split(":")[1]}`
        : null;
    }
    return time && time.includes(":")
      ? `${time.split(":")[0]}h:${time.split(":")[1]}m`
      : null;
  }
  return null;
};

export const isBase64Encoded = (str) => {
  if (str === null || str === "") return false;
  const base64Regex =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  return base64Regex.test(str);
};

export const convertTimeToDatetimeLocal = (
  shiftDate,
  timeValue,
  isNextDay = false
) => {
  if (!timeValue) return "";

  const currentDate = new Date(shiftDate);
  const [hours, minutes] = timeValue.split(":");

  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);
  if (isNextDay) {
    return moment(currentDate)
      .add(1, "days")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  }

  return moment(currentDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};

export const convertTimeToDatetimeLocalWithoutMoment = (
  shiftDate,
  timeValue,
  isNextDay = false
) => {
  if (!timeValue || !shiftDate) return "";
  let currentDate;
  if (shiftDate && shiftDate?.includes("T")) {
    const [date, time] = shiftDate?.split("T");

    const [year, month, day] = date?.split("-").map(Number);
    const [hours, minutes] = timeValue.split(":").map(Number);

    currentDate = new Date(year, month - 1, day, hours, minutes, 0, 0);
  } else {
    const [date, time] = shiftDate?.split(" ");

    const [year, month, day] = date?.split("-").map(Number);
    const [hours, minutes] = timeValue.split(":").map(Number);
    currentDate = new Date(year, month - 1, day, hours, minutes, 0, 0);
  }

  if (isNextDay) {
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const pad = (num) => num.toString().padStart(2, "0");
  const formattedYear = currentDate.getFullYear();
  const formattedMonth = pad(currentDate.getMonth() + 1);
  const formattedDay = pad(currentDate.getDate());
  const formattedHours = pad(currentDate.getHours());
  const formattedMinutes = pad(currentDate.getMinutes());
  const formattedSeconds = pad(currentDate.getSeconds());
  const formattedMilliseconds = currentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}T${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;

  return formattedDate;
};
export const isCheckNextDayShift = (shift_date, start_time, end_time) => {
  if (
    shift_date &&
    shift_date !== null &&
    start_time &&
    start_time !== null &&
    end_time &&
    end_time !== null &&
    end_time !== undefined
  ) {
    const time1 = moment(start_time, "HH:mm");
    const time2 = moment(end_time, "HH:mm");

    if (time2.diff(time1, "minutes") <= 0) {
      return convertTimeToDatetimeLocal(shift_date, end_time, true);
    }
    return false;
  }
  return false;
};

export const getHrAndMinsFormatting = (value) => {
  if (!value) return "";
  if (value.includes(":")) {
    return `${value.split(":")[0]}${value.split(":")[0] > 1 ? "hrs" : "hr"}${
      value.split(":")[1]
    }${value.split(":")[1] > 1 ? "mins" : "min"}`;
  }
  return "00hr00min";
};

// Function to convert 24-hour time to 12-hour time with AM and PM
export const convertTo12HourFormat = (time24) => {
  if (time24 === null || typeof time24 === "undefined") {
    return "";
  }

  const [hours, minutes] = time24.split(":");
  let period = "AM";

  let hours12 = parseInt(hours, 10);

  if (hours12 >= 12) {
    period = "PM";
    if (hours12 > 12) {
      hours12 -= 12;
    }
  }

  return `${hours12}:${minutes} ${period}`;
};

export const formatDateTimeWithAMPM = (date) => {
  const currentDate = new Date(date);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[currentDate.getMonth()]; // Get month name
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours() % 12 || 12).padStart(2, "0"); // Convert to 12-hour format
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const ampm = currentDate.getHours() >= 12 ? "PM" : "AM";

  const formattedDateTime = `${hours}:${minutes} ${ampm} `;

  return formattedDateTime;
};

export function formatDateToAustralian(date) {
  const currentDate = new Date(date);
  const options = { day: "numeric", month: "numeric", year: "numeric" };
  return currentDate.toLocaleDateString("en-AU", options);
}

export function isDateBeforeToday(dateString) {
  const dateToCheck = new Date(dateString); // Convert the input date string to a Date object
  const today = new Date(); // Get the current date

  // Compare the input date with today's date
  return dateToCheck < today;
}
export const getTimesheetProgressLabel = (value) => {
  if (value) {
    const arr = Object.keys(TIMESHEET_PROGRESS_STATUS).map(
      (key) => TIMESHEET_PROGRESS_STATUS[key]
    );
    return arr.find((x) => x.value === value)?.label ?? "";
  }
  return "";
};

export const formatTimeSheetLength = (
  totalSeconds = null,
  format = "digit"
) => {
  const hours = Math.floor(totalSeconds / 3600);
  if (totalSeconds === null) return null;
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  if (format === "digit") {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  return `${hours.toString().padStart(2, "0")}${
    hours.toString().padStart(2, "0") > 1 ? "hrs" : "hr"
  }${minutes.toString().padStart(2, "0")}${
    minutes.toString().padStart(2, "0") > 1 ? "mins" : "min"
  }`;
};

export const checkDisabilityOfShiftStatus = (status) => {
  if (status) {
    // return status === SHIFT_STATUS.EXPIRED || status === SHIFT_STATUS.ACCEPTED
    return status === SHIFT_STATUS.ACCEPTED ? true : false;
  }
  return false;
};

export const groupByPermissionsByName = (permissions = []) => {
  const groupedPermissions = permissions.reduce((result, item) => {
    if (item.parent_id === null) {
      let name = item.name;
      if (item.name === "participant.dashboard") {
        name = "dashboard";
      } else if (item.name.includes("crm.form")) {
        name = "crm_form";
      } else if (item.name.includes("hrm.form")) {
        name = "hrm_form";
      } else if (item.name.includes(".")) {
        name = item.name.split(".")[0];
      } else {
        name = item.name;
      }

      result[name] = [
        item,
        ...permissions.filter((child) => child.parent_id === item.id),
      ];
    }
    return result;
  }, {});

  return groupedPermissions;
};
export const checkRunningShiftStatus = (status) => {
  if (status) {
    // return status === SHIFT_STATUS.ACCEPTED || status === SHIFT_STATUS.NA
    return status === SHIFT_STATUS.NA ? true : false;
  }
  return false;
};

export const downloadFileFromServer = async (fileUrl, fileName) => {
  try {
    if (fileUrl && fileName) {
      const response = await fetch(baseURLImage(fileUrl));
      const blob = await response.blob();
      FileSaver.saveAs(blob, fileName);
    }
    return null;
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const isExistPayrollPlatform = (type = "") => {
  return Object.values(PAYROLL_PLATFORMS).findIndex((x) => x === type) !== -1;
};

export const hasCheckTimesheetStatus = (
  item = {},
  statusToCheck = "success"
) => {
  return Object.values(PAYROLL_PLATFORMS).some(
    (platform) => item[`${platform}_export_status`] === statusToCheck
  );
};

export const checkWorkerAnyTimesheetsFailedOrError = (data = []) => {
  return data.filter((item) => {
    const payrollStatuses = Object.values(PAYROLL_PLATFORMS).map(
      (platform) => item[`${platform}_export_status`]
    );
    return payrollStatuses.some(
      (status) =>
        status === PAYROLL_EXPORT_STATUS.FAILED ||
        status === PAYROLL_EXPORT_STATUS.ERROR
    );
  });
};

export const checkWorkerAllTimesheetsSuccess = (data = []) => {
  return data.filter((item) => {
    const payrollStatuses = Object.values(PAYROLL_PLATFORMS).map(
      (platform) => item[`${platform}_export_status`]
    );
    return payrollStatuses.some(
      (status) => status === PAYROLL_EXPORT_STATUS.SUCCESS
    );
  });
};

export const getAllNonSuccessTimesheets = (data = []) => {
  return data.filter((item) => {
    const payrollStatuses = Object.values(PAYROLL_PLATFORMS).map(
      (platform) => item[`${platform}_export_status`]
    );
    return !payrollStatuses.some(
      (status) => status === PAYROLL_EXPORT_STATUS.SUCCESS
    );
  });
};

export const filterNonPayrollRecords = (item = {}) => {
  return Object.values(PAYROLL_PLATFORMS).some(
    (platform) => !_.isEmpty(item?.[`${platform}_worker_id`] || null)
  );
};

export const getPayrollPlatformByUsingWorkerId = (item) => {
  return (
    (item &&
      Object.entries(PAYROLL_PLATFORMS).find(
        ([_, platform]) => !!item?.[`${platform}_worker_id`]
      ))?.[1] || null
  );
};

export const currentLoggedInIdAndUUID = (isAddSpAdmin = false) => {
  const currentSpUser = store?.getState()?.spAdmin?.getIn(["spSetting"]);
  let currentLoggedInUser = store?.getState()?.users?.getIn(["user"]);
  if (currentLoggedInUser) {
    const data = JSON.parse(JSON.stringify(currentLoggedInUser));
    currentLoggedInUser = data;
  }
  if (
    parseBooleanVal(isAddSpAdmin) &&
    roleAccess([USER_ROLE.Sp_Admin]) &&
    currentSpUser &&
    currentSpUser?.id
  ) {
    return { id: currentSpUser.id, uuid: currentSpUser.uuid };
  } else if (
    parseBooleanVal(isAddSpAdmin) &&
    currentLoggedInUser &&
    !roleAccess([USER_ROLE.Sp_Admin]) &&
    currentLoggedInUser?.id &&
    currentLoggedInUser?.uuid
  ) {
    return {
      id:
        currentLoggedInUser?.sp_id || currentLoggedInUser?.service_provider?.id,
      uuid: currentLoggedInUser.uuid,
    };
  } else if (
    currentLoggedInUser &&
    currentLoggedInUser?.id &&
    isAddSpAdmin === false
  ) {
    return { id: currentLoggedInUser.id, uuid: currentLoggedInUser.uuid };
  }
  return false;
};

export const convertCentsToDollars = (cents) => {
  return cents / 100;
};

export const getRemainingTrialDays = (trialEndDate) => {
  if (!trialEndDate) return;
  const today = moment().startOf("day");
  const end = moment(trialEndDate).startOf("day");
  // const end = moment("2024-02-14").startOf("day");

  const remainingDays = end.diff(today, "days");
  return remainingDays;
};

export const getExpireDate = (trialEndDate) => {
  if (!trialEndDate) return;
  const end = moment(trialEndDate);
  const formattedDate = end.format("Do MMMM YYYY");
  return formattedDate;
};

export const formatAmount = (amount) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export function customFormatDate(dateString) {
  if (dateString === null || typeof dateString === "undefined") {
    return "";
  }
  const [datePart] = dateString.split("T");

  const [year, month, day] = datePart.split("-");

  return `${day}/${month}/${year}`;
}

export function completeFormatDate(dateString) {
  const [datePart, timePart] = dateString.split("T");

  const [year, month, day] = datePart.split("-");

  const time = timePart ? timePart.split(".")[0] : "00:00:00";

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export function convertTimeToDatetimeWithLocal(time) {
  return moment(time).local().format("YYYY-MM-DDTHH:mm:ss");
}

export const alreadyNextDate = (startDate, endDate) => {
  const startDateTime = new Date(`${startDate}`);
  const endDateTime = new Date(`${endDate}`);

  return endDateTime.getDate() > startDateTime.getDate();
};

export const formatDateForWorkerTime = (dateTimeString) => {
  if (!dateTimeString) return;
  // Extract the date part from the dateTimeString
  const datePart = dateTimeString?.split(" ")[0];

  // Split the date part into year, month, and day
  const [year, month, day] = datePart?.split("-");

  // Return the formatted date
  return `${day}-${month}-${year}`;
};
export function getFileExtensionForDocuments(url) {
  if (!url) return;

  let extension = url.split(".").pop();
  extension = extension.split(/[?#]/)[0];
  return extension;
}

export const parsePriceInfo = (input) => {
  const regex =
    /^(\d+)\s+to\s+(\d+)\s+Participants\s+\$([\d.]+)\s+(.*?)\s+\((\d+)\s+Year(?:s)?\)$/i;

  const match = regex.exec(input);

  if (!match) {
    return null;
  }
  const [, rangeStart, rangeEnd, amount, additionalText, duration] = match;
  return {
    description: `${rangeStart} to ${rangeEnd} Participants`,
    amount,
    frequency: additionalText,
    year: duration,
  };
};

export function formatDateForApprovedTimesheet(dateString) {
  if (!dateString) return;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract components from the date string
  const year = dateString.slice(2, 4);
  const month = parseInt(dateString.slice(5, 7), 10);
  const day = parseInt(dateString.slice(8, 10), 10);
  let hours = parseInt(dateString.slice(11, 13), 10);
  const minutes = parseInt(dateString.slice(14, 16), 10);

  // Convert 24-hour time to 12-hour time
  const suffix = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;

  // Add appropriate suffix to the day
  const getDayWithSuffix = (day) => {
    if (day > 3 && day < 21) return day + "th";
    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  };

  const dayWithSuffix = getDayWithSuffix(day);

  // Format the minutes to always be two digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Build and return the final formatted string
  return `${dayWithSuffix} ${
    months[month - 1]
  } ${year} - ${hours}:${formattedMinutes} ${suffix}`;
}

export const getCurrentTimezone = () => {
  return momentTimeZone.tz.guess();
};

export const exportTOExcel = async (exportData) => {
  if (!exportData || exportData === undefined) return "";

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;";
  const fileExtenstion = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(exportData);

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: fileType });

  await FileSaver.saveAs(data, "Excel-Export" + fileExtenstion);
};

export const formatDateReporting = (date) => {
  if (!date) return "";
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export const getWorkerTimesheetLength = (workerStartTime, workerEndTime) => {
  if (!workerStartTime || !workerEndTime) return null;

  const startTime = new Date(workerStartTime);
  const endTime = new Date(workerEndTime);

  const diffInMs = endTime - startTime;

  const totalMinutes = diffInMs / (1000 * 60);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);

  return `${hours}h${minutes}m`;
};

export const formatDateForWorkerStartTime = (dateString) => {
  if (typeof dateString !== "string" || dateString.trim() === "") {
    return "";
  }

  const datePart = dateString.split(" ")[0];

  if (!/^\d{4}-\d{2}-\d{2}$/.test(datePart)) {
    return "";
  }

  const [year, month, day] = datePart.split("-");

  if (
    !year ||
    !month ||
    !day ||
    year.length !== 4 ||
    month.length !== 2 ||
    day.length !== 2
  ) {
    return "";
  }

  return `${day}/${month}/${year}`;
};

export const getDifferenceArray = (array1 = [], array2 = [], key = "value") => {
  const set2 = new Set(array2.map((item) => item[key]));

  return array1.filter((item) => !set2.has(item[key]));
};

export const addMonths = (date, months) => {
  if (!date || !months) return "";
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
};

export const addDays = (date, days) => {
  if (!date || !days) return "";
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const truncateTextReporting = (text, maxTextLength = 10) => {
  if (text?.length > maxTextLength) {
    return `${text.substring(0, maxTextLength)}...`;
  }
  return text;
};

export const fetchOptionsForWorkerAndParticpant = async (selectFor) => {
  try {
    const payload = {
      filters: { search: "", page: 1, per_page: 1000 },
    };
    const response =
      selectFor === "worker"
        ? await api.teamManagmentListRequest(payload)
        : await api.participantListRequest(payload);

    if (selectFor === "worker") {
      return (
        response.data?.employees?.data?.map((item) => ({
          ...item,
          label: capitalizeAllLetters(`${item.first_name} ${item.last_name}`),
          value: item.uuid,
          icon: item?.photo
            ? baseURLImage(item.photo)
            : getNameProfile(item.first_name, item.last_name || " "),
        })) || []
      );
    }
    return (
      response.data?.participant?.data?.map((item) => ({
        ...item,
        label: capitalizeAllLetters(`${item.first_name} ${item.last_name}`),
        value: item.uuid,
        icon: item?.profile_picture
          ? baseURLImage(item.profile_picture)
          : getNameProfile(item.first_name, item.last_name || " "),
      })) || []
    );
  } catch (error) {
    console.error("Error fetching options:", error);
    return [];
  }
};

export const getFileNameAndExtension = (url) => {
  if (!url) return { fileName: "", extension: "" };

  const fileNameWithExtension = url.split("/").pop();
  const lastDotIndex = fileNameWithExtension.lastIndexOf(".");

  return {
    fileName:
      lastDotIndex === -1
        ? fileNameWithExtension
        : fileNameWithExtension.slice(0, lastDotIndex),
    extension:
      lastDotIndex === -1 ? "" : fileNameWithExtension.slice(lastDotIndex),
  };
};

export const getMimeType = (extension) => {
  return MIME_TYPES[extension.toLowerCase()] || "application/octet-stream";
};

export const getFileFromServerAsFileType = async (url, file_name = null) => {
  try {
    const { fileName, extension } = getFileNameAndExtension(
      file_name ? file_name : url
    );
    const fileType = getMimeType(extension);
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }

    const blob = await response.blob();

    const file = new File([blob], `${fileName}${extension}`, {
      type: fileType,
    });

    return file;
  } catch (error) {
    console.error("Error fetching file:", error);
    throw error;
  }
};

export function CustomFormatDateForReporting(dateString) {
  if (!dateString) return;
  const [day, month, year] = dateString.split("-");

  return `${day}/${month}/${year}`;
}

export function CapitalizeLabel(string) {
  if (!string || string === undefined) return "";

  const label = string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return label;
}


export const convertDaysName = (days) => {

  if (typeof days !== 'object' || !days) {
      return [];
  }

 
  const dayMapping = {
      Mon: "monday",
      Tue: "tuesday",
      Wed: "wednesday",
      Thu: "thursday",
      Fri: "friday",
      Sat: "saturday",
      Sun: "sunday"
  };


  const result = Object.entries(days)
      .filter(([key, value]) => value) 
      .map(([key]) => dayMapping[key]) 

  return result;
};

export const formatRecurringValue = (value) => {
  if (!value) return;

  const [firstPart, secondPart] = value.split("_");
  return `${firstPart} ${secondPart || ''}`.trim();
};


export const convertWeeksToFormat = (value) => {

  if (!value) return;
  const modifiedVal = value.split(" ");
  const quantity = modifiedVal[0];
  const unit = modifiedVal[1].toLowerCase(); 

  let formattedUnit;
  if (unit.startsWith("week")) {
      formattedUnit = "Week";
  } else if (unit.startsWith("fortnight")) {
      formattedUnit = "Fortnight";
  } else if (unit.startsWith("month")) {
      formattedUnit = "Month";
  } else {
      return null; 
  }


  return `${quantity}_${formattedUnit}`;
};



